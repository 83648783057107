import React from "react";

const Top10mostTradedCryptocurrencies = () => {
    return (
        <>
            <div>
                <div className="trade-content">
                    <img className="trade-img" src="../../../../assets/images/blog/Group-66.png" alt="" />
                </div>
                <div className="trade-data">
                    <div className="container">
                        <h5 className="trade-title">Future of Cryptocurrency</h5>
                        <p className="trade-subtitle">Cryptocurrency is a digital currency that became quite a trend today thanks to its
                            decentralized nature and low transaction fees. This digital currency has been seen as the future of online
                            money and will most likely take over the market in
                            about a few years from now.</p>
                        <p className="trade-subtitle"> Many companies nowadays are turning their eyes to cryptocurrencies because they are
                            bound to make more profit out of it. These days, you can easily buy and sell goods or services with
                            cryptocurrencies. There are even digital games nowadays
                            that will let you earn some type of cryptocurrency.
                        </p>
                        <h5 className="trade-title">What Exactly are Cryptocurrencies?</h5>
                        <p className="trade-subtitle">Cryptocurrency is a digital currency that became quite a trend today thanks to its
                            decentralized nature and low transaction fees. This digital currency has been seen as the future of online
                            money and will most likely take over the market in
                            about a few years from now.</p>
                        <p className="trade-subtitle"> Many companies nowadays are turning their eyes to cryptocurrencies because they are
                            bound to make more profit out of it. These days, you can easily buy and sell goods or services with
                            cryptocurrencies. There are even digital games nowadays
                            that will let you earn some type of cryptocurrency.
                        </p>
                        <h5 className="trade-title ">The Most Traded Cryptocurrenciesy</h5>
                        <p className="trade-subtitle">Without further ado, here are the top 10 most traded cryptocurrencies in the market
                            today.</p>
                        <div className="row trade-lists" >
                            <div className="col-md-2">
                                <img className=" trade-images mr-3" src="../../../assets/images/blog/btc.png" alt="" />
                            </div>
                            <div className="col-md-10 media-body">
                                <h5 className="trade-title mt-0">Bitcoin (BTC)</h5>
                                <p className="trade-contents">Considered the most famous of all the current cryptocurrencies out there,
                                    Bitcoin is the first and most expensive cryptocurrency ever. </p>
                                <p className="trade-contents"> Bitcoin actually started way back in 2009, Satoshi Nakamoto first introduced
                                    it. No one really knows whether Satoshi Nakamoto is a person or a group of people. Actually, this
                                    mysterious creator hasn’t been heard of since 2010.
                                </p>
                            </div>
                        </div>
                        <div className="row media trade-lists">
                            <div className="col-md-2">
                                <img className=" trade-images mr-3" src="../../../assets/images/blog/eth.png" alt="" />
                            </div>
                            <div className="col-md-10 media-body">
                                <h5 className="trade-title mt-0">Ethereum (ETH)</h5>
                                <p className="trade-contents"> Started from a crowdfunding project, Ethereum was actually built as an
                                    alternative for Bitcoin. The creator of Ethereum, Vitalik Butterin, actually had the idea already in
                                    2013 about this type of blockchain currency, but he lacked
                                    the necessary funding.
                                </p>
                                <p className="trade-contents">Thanks to the funds he received from crowdfunding, Butterin successfully
                                    developed the Ethereum program and launched it in July of 2015. Although not as popular as Bitcoin
                                    yet, Ethereum can overtake Bitcoin in the future, whether we
                                    are talking about popularity or market value. </p>
                            </div>
                        </div>
                        <div className="row media trade-lists">
                            <div className="col-md-2">
                                <img className=" trade-images mr-3" src="../../../assets/images/blog/lite.png" alt="" />
                            </div>
                            <div className="col-md-10 media-body">
                                <h5 className="trade-title mt-0">Litecoin (LTC)</h5>
                                <p className="trade-contents"> Litecoin was first created by Charlie Lee, a graduate from MIT and also a
                                    former engineer of Google. First launched back in 2011, Litecoin is considered to be among the first
                                    of all the other cryptocurrencies besides Bitcoin. Litecoin
                                    is pretty closely similar to Bitcoin but a little faster in confirming the transaction thanks to its
                                    quicker block generation rate.</p>
                            </div>
                        </div>
                        <div className="row media trade-lists">
                            <div className="col-md-2">
                                <img className=" trade-images mr-3" src="../../../assets/images/blog/xrp.png" alt="" />
                            </div>
                            <div className="col-md-10 media-body">
                                <h5 className="trade-title mt-0">XRP</h5>
                                <p className="trade-contents">XRP is a cryptocurrency developed by Ripple Labs. Built solely for easier
                                    digital payments, XRP is considered to be a faster and more efficient method for digital payments
                                    around the world. </p>
                            </div>
                        </div>
                        <div className="row media trade-lists">
                            <div className="col-md-2">
                                <img className=" trade-images mr-3" src="../../../assets/images/blog/ada.png" alt="" />
                            </div>
                            <div className="col-md-10 media-body">
                                <h5 className="trade-title mt-0">Cardano (ADA)</h5>
                                <p className="trade-contents">Cardano is another decentralized cryptocurrency that boasts itself as a
                                    proof-of-stake blockchain platform. Cardano was actually co-founded by one of the founding members
                                    of Ethereum, Charles Hoskinson. Due to disagreements with the
                                    other founding members of Ethereum, Charles left Ethereum and co-founded Cardano. </p>
                            </div>
                        </div>
                        <div className="row media trade-lists">
                            <div className="col-md-2">
                                <img className=" trade-images mr-3" src="../../../assets/images/blog/dot.png" alt="" />
                            </div>
                            <div className="col-md-10 media-body">
                                <h5 className="trade-title mt-0">Polkadot (DOT)</h5>
                                <p className="trade-contents">Polkdot is another cryptocurrency that was created by a former founding member
                                    of Ethereum, Gavin Wood. Another case of disagreement has led to Gavin creating his very own
                                    cryptocurrency. As of August 2021, Polkadot has a value of
                                    17.97$. </p>
                            </div>
                        </div>
                        <div className="row media trade-lists">
                            <div className="col-md-2">
                                <img className=" trade-images mr-3" src="../../../assets/images/blog/usdt.png" alt="" />
                            </div>
                            <div className="col-md-10 media-body">
                                <h5 className="trade-title mt-0">Tether (USDT)</h5>
                                <p className="trade-contents"> Unlike most of the cryptocurrencies available on the market today, Tether is
                                    a lot more stable because it is being backed by real-world currencies such as US dollars and UK
                                    Euros. It is considered the best cryptocurrency for investors
                                    that fear the volatility of most cryptocurrencies.</p>
                            </div>
                        </div>
                        <div className="row media trade-lists">
                            <div className="col-md-2">
                                <img className=" trade-images mr-3" src="../../../assets/images/blog/bnb.png" alt="" />
                            </div>
                            <div className="col-md-10 media-body">
                                <h5 className="trade-title mt-0">Binance Coin (BNB)</h5>
                                <p className="trade-contents">Binance Coin is a form of cryptocurrency that is specifically used for paying
                                    fees on the Binance website. Binance is actually considered to be the biggest crypto exchange in the
                                    whole world. </p>
                            </div>
                        </div>
                        <div className="row media trade-lists">
                            <div className="col-md-2">
                                <img className=" trade-images mr-3" src="../../../assets/images/blog/xrm.png" alt="" />
                            </div>
                            <div className="col-md-10 media-body">
                                <h5 className="trade-title mt-0">Monero (XMR)</h5>
                                <p className="trade-contents">Monero is just like any other type of cryptocurrency; it is secured, private,
                                    and, most importantly, untraceable. Monero was first launched way back in 2014. Still, thanks to
                                    some cryptocurrency enthusiasts, it managed to gain traction
                                    in the online market today. As of August 2021, Monero has a value of 231.74$ per coin. </p>
                            </div>
                        </div>
                        <div className="row media trade-lists mb-4">
                            <div className="col-md-2">
                                <img className=" trade-images mr-3" src="../../../assets/images/blog/doge.png" alt="" />
                            </div>
                            <div className="col-md-10 media-body">
                                <h5 className="trade-title mt-0">Dogecoin (DOGE)</h5>
                                <p className="trade-contents">Started as a meme cryptocurrency, Dogecoin surprisingly became quite a hit
                                    thanks to the billionaire Elon Musk. Unlike most cryptocurrencies, like Bitcoin or Ethereum, there
                                    isn’t any limit to how much Dogecoin can be mined. </p>
                                <p className="trade-contents"> Dogecoin initially had a value of 0.0002$ since its launch, but by August of
                                    2021, it now has a value of .21$. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Top10mostTradedCryptocurrencies;