import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const CoinovyBlog = () => {
    const location = useLocation();


    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, []);
    return (
        <>
            <div className="bitcoin-iming-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <NavLink to="/begineerguide" target="_blank" style={{ textDecoration: 'none' }}>
                                <div className="card inside-matters">
                                    <NavLink to="/begineerguide" target="_blank" style={{ textDecoration: 'none' }}>
                                        <img className="card-img-top inside-image-content" src="../../../assets/images/blog/begining-image.png" alt="" />
                                    </NavLink>
                                    <div className="card-body inside-bodys">
                                        <h5 className="card-title inside-title">Beginner Guide to Cryptocurrency</h5>
                                        <p className="card-text inside-sub">As more people show interest in joining the crypto industry,
                                            a
                                            common struggle among novices is on where and how to start. As many might have noticed,
                                            a
                                            majority of crypto investors are already well-versed in the field as</p>
                                        <div className="read-content">
                                            <NavLink to="/begineerguide" className="btn read-more">Read More <i className="fas fa-arrow-right ml-2" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <NavLink to="/bitcoinmining" target="_blank" style={{ textDecoration: 'none' }}>
                                        <div className="card inside-matters2">
                                            <img className="card-img-top inside-image-content" src="../../../assets/images/blog/1.png" alt="" />
                                            <div className="card-body inside-bodys">
                                                <h5 className="card-title inside-title inside-title1">Bitcoin Mining: How Does It Work?
                                                </h5>
                                                <p className="card-text inside-sub inside-sub1">The world is suddenly going crazy with
                                                    Bitcoin. Cryptocurrency jargon has been popping up out of nowhere. Like most
                                                    people,
                                                </p>
                                                <div className="read-content">
                                                    <NavLink to="/bitcoinmining" className="btn read-more">Read More <i className="fas fa-arrow-right ml-2" /></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col-md-12">
                                    <NavLink to="/cryptoexperts" target="_blank" style={{ textDecoration: 'none' }}>
                                        <div className="card inside-matters2 inside-matters3">
                                            <img className="card-img-top inside-image-content" src="../../../assets/images/blog/2.png" alt="" />
                                            <div className="card-body inside-bodys">
                                                <h5 className="card-title inside-title inside-title1">Crypto Experts: The Best Personal
                                                    Finance Experts You Should Follow</h5>
                                                <p className="card-text inside-sub inside-sub1">Cryptocurrency is a recent trend that
                                                    has
                                                    rapidly gained popularity over the past few years. Many people have doubled and
                                                    even
                                                    tripled their money and investment thanks to this digital</p>
                                                <div className="read-content">
                                                    <NavLink to="/cryptoexperts" className="btn read-more">Read More <i className="fas fa-arrow-right ml-2" /></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <NavLink to="/cryptonews" target="_blank" style={{ textDecoration: 'none' }}>
                                <div className="card inside-matters2">
                                    <img className="card-img-top inside-image-content" src="../../../assets/images/blog/3.png" alt="" />
                                    <div className="card-body inside-bodys">
                                        <h5 className="card-title inside-title">Crypto News: Is Bitcoin’s Price Rising?</h5>
                                        <p className="card-text inside-sub">Since its initial launch in 2009, Bitcoin has rapidly
                                            gained
                                            popularity and became one of the most prominent cryptocurrencies worldwide.</p>

                                        <div className="read-content">
                                            <NavLink to="/cryptonews" className="btn read-more">Read More <i className="fas fa-arrow-right ml-2" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-3">
                            <NavLink to="/cryptocurrencysecurity" target="_blank" style={{ textDecoration: 'none' }}>
                                <div className="card inside-matters2">
                                    <img className="card-img-top inside-image-content" src="../../../assets/images/blog/4.png" alt="" />
                                    <div className="card-body inside-bodys">
                                        <h5 className="card-title inside-title">Cryptocurrency Security: Tips to Invest in
                                            Cryptocurrency
                                            Safely</h5>
                                        <p className="card-text inside-sub">Investing in virtual currency like cryptocurrency has
                                            become
                                            quite a thing nowadays. Most people from around the world are now using</p>
                                        <div className="read-content">
                                            <NavLink to="/cryptocurrencysecurity" className="btn read-more">Read More <i className="fas fa-arrow-right ml-2" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-3">
                            <NavLink to="/blockchain-technology" target="_blank" style={{ textDecoration: 'none' }}>
                                <div className="card inside-matters2">
                                    <img className="card-img-top inside-image-content" src="../../../assets/images/blog/5.png" alt="" />
                                    <div className="card-body inside-bodys">
                                        <h5 className="card-title inside-title">How Does Blockchain Technology Work?</h5>
                                        <p className="card-text inside-sub">With cryptocurrencies rapidly growing in popularity over
                                            the
                                            years, the interest in the technology behind these digital currencies has also
                                            significantly
                                            increased.</p>

                                        <div className="read-content">
                                            <NavLink to="/blockchain-technology" className="btn read-more">Read More <i className="fas fa-arrow-right ml-2" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-3">
                            <NavLink to="/crypto-market" target="_blank" style={{ textDecoration: 'none' }}>
                                <div className="card inside-matters2">
                                    <img className="card-img-top inside-image-content" src="../../../assets/images/blog/6.png" alt="Card cap" />
                                    <div className="card-body inside-bodys">
                                        <h5 className="card-title inside-title">How the Pandemic Affected the Crypto Market</h5>
                                        <p className="card-text inside-sub">The COVID-19 pandemic has undoubtedly changed numerous
                                            things
                                            worldwide since it first broke out – from people’s daily routines to the global
                                            economy.</p>
                                        <div className="read-content">
                                            <NavLink to="/crypto-market" className="btn read-more">Read More <i className="fas fa-arrow-right ml-2" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <NavLink to="/the-future-of-cryptocurrency" target="_blank" style={{ textDecoration: 'none' }}>
                                <div className="card inside-matters2">
                                    <img className="card-img-top inside-image-content" src="../../../assets/images/blog/7.png" alt="Card cap" />
                                    <div className="card-body inside-bodys">
                                        <h5 className="card-title inside-title">The Future of Cryptocurrency: What You Need to Know
                                        </h5>
                                        <p className="card-text inside-sub">In recent years, the trajectory of Cryptocurrency has
                                            always
                                            been going up. This is why many wonder whether this will continue to be a trend or
                                            eventually flop in the future.</p>
                                        <div className="read-content">
                                            <NavLink to="/the-future-of-cryptocurrency" className="btn read-more">Read More <i className="fas fa-arrow-right ml-2" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-3">
                            <NavLink to="/top-10-most-traded-cryptocurrencies" target="_blank" style={{ textDecoration: 'none' }}>
                                <div className="card inside-matters2">
                                    <img className="card-img-top inside-image-content" src="../../../assets/images/blog/8.png" alt="Card cap" />
                                    <div className="card-body inside-bodys">
                                        <h5 className="card-title inside-title">Top 10 Most Traded Cryptocurrencies</h5>
                                        <p className="card-text inside-sub">Cryptocurrency is a digital currency that became quite a
                                            trend
                                            today thanks to its decentralized nature and low transaction fees.</p>
                                        <div className="read-content">
                                            <NavLink to="/top-10-most-traded-cryptocurrencies" className="btn read-more">Read More
                                                <i className="fas fa-arrow-right ml-2" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-3">
                            <NavLink to="/cryptocurrency" target="_blank" style={{ textDecoration: 'none' }}>
                                <div className="card inside-matters2">
                                    <img className="card-img-top inside-image-content" src="../../../assets/images/blog/9.png" alt="Card cap" />
                                    <div className="card-body inside-bodys">
                                        <h5 className="card-title inside-title">Cryptocurrency: Why is it a Good Investment?</h5>
                                        <p className="card-text inside-sub">In recent years, more and more prominent individuals
                                            advocate
                                            investing in cryptocurrency because of its lucrative potential. However, what
                                            exactly
                                            makes
                                            it a good </p>
                                        <div className="read-content">
                                            <NavLink to="/cryptocurrency" className="btn read-more">Read More <i className="fas fa-arrow-right ml-2" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default CoinovyBlog;