// import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import Header from './Components/Common/Header/Header';
import Footer from './Components/Common/Footer/Footer';
import C2f from './Components/C2f/C2f';
import AboutCoinovy from './Components/AboutCoinovy/AboutCoinovy';
import CoinovyBlog from './Components/CoinovyBlog/CoinovyBlog';
import BegineerGuide from './Components/CoinovyBlog/begineerguide/BegineerGuide';
import BitcoinMining from './Components/CoinovyBlog/BitcoinMining/BitcoinMining';
import CryptoExperts from './Components/CoinovyBlog/CryptoExperts/CryptoExperts';
import CryptoNews from './Components/CoinovyBlog/CryptoNews/CryptoNews';
import CryptocurrencySecurity from './Components/CoinovyBlog/CryptocurrencySecurity/CryptocurrencySecurity';
import BlockchainTechnology from './Components/CoinovyBlog/BlockchainTechnology/BlockchainTechnology';
import CryptoMarket from './Components/CoinovyBlog/CryptoMarket/CryptoMarket';
import TheFutureofCryptocurrency from './Components/CoinovyBlog/TheFutureofCryptocurrency/TheFutureofCryptocurrency';
import Top10mostTradedCryptocurrencies from './Components/CoinovyBlog/Top10mostTradedCryptocurrencies/Top10mostTradedCryptocurrencies';
import Cryptocurrency from './Components/CoinovyBlog/Cryptocurrency/Cryptocurrency';
import CoinovyMedia from './Components/CoinovyMedia/CoinovyMedia';
import Help from './Components/Help/Help';
import Careers from './Components/Careers/Careers';
import Wallets from './Components/Wallets/Wallets';
import Cards from './Components/Cards/Cards';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/c2f' element={<C2f />} />
        <Route path='/about-coinovy' element={<AboutCoinovy />} />
        <Route path='/coinovy-blog' element={<CoinovyBlog />} />
        <Route path='/begineerguide' element={<BegineerGuide />} />
        <Route path='/bitcoinmining' element={<BitcoinMining />} />
        <Route path='/cryptoexperts' element={<CryptoExperts />} />
        <Route path='/cryptonews' element={<CryptoNews />} />
        <Route path='/cryptocurrencysecurity' element={<CryptocurrencySecurity />} />
        <Route path='/blockchain-technology' element={<BlockchainTechnology />} />
        <Route path='/crypto-market' element={<CryptoMarket />} />
        <Route path='/the-future-of-cryptocurrency' element={<TheFutureofCryptocurrency />} />
        <Route path='/top-10-most-traded-cryptocurrencies' element={<Top10mostTradedCryptocurrencies />} />
        <Route path='/cryptocurrency' element={<Cryptocurrency />} />
        <Route path='/coinovy-media' element={<CoinovyMedia />} />
        <Route path='/help' element={<Help />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/wallets' element={<Wallets />} />
        <Route path='/cards' element={<Cards />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
