import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const CoinovyMedia = () => {
    const location = useLocation();


    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, []);
    return (
        <>
            <div className="news-content">
                <div className="container">
                    {/* GENERAL ARTICLES ON COINOVY */}
                    <h3 className="news-title">GENERAL ARTICLES ON COINOVY</h3>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-1" to="https://maboot.com/how-coinovy-keeps-your-crypto-assets-liquid/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/news-3.jpeg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on August 4, 2021</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">How Coinovy keeps your Crypto Assets Liquid</h3>
                                            <p className="news-subcontent">In the financial markets, liquidity is typically used to
                                                indicate the ease of converting an asset into cash. In commercial assets
                                                including
                                                cryptocurrency, </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-1" to="https://www.soup.io/exciting-features-of-coinovy-that-you-should-know" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/news-1.jpeg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on August 19, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Exciting Features of Coinovy that you should Know</h3>
                                            <p className="news-subcontent">Coinovy will never compromise on quality or service when it
                                                comes to customer experience. They have consistently outperformed their service.</p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://kagay-an.com/things-you-need-to-know-about-coinovy/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/news-4.jpeg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on August 19, 2021</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Things you need to know about Coinovy</h3>
                                            <p className="news-subcontent">A crypto exchanger is a platform used for buying,
                                                selling,
                                                and trading cryptocurrencies. You may swap from one cryptographic to another by
                                                using exchanges, e.g., </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://www.cryptodaily.co.uk/preview/52999" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/nesw-2.jpeg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on September 02, 2021</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">A Beginners Guide To Coinovy</h3>
                                            <p className="news-subcontent">With the invention of cryptocurrency, and seeing it taking
                                                the world by storm in just a short period of time, has been phenomenal. </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://cryptonews.com/news/coinovy-predicted-to-thrive-in-the-face-of-uncertainty.htm" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/news-5.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on 09 September 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Coinovy Predicted to Thrive in the Face of Uncertainty</h3>
                                            <p className="news-subcontent">Coinovy is an all-encompassing crypto platform that gives
                                                each crypto
                                                monetary providers, and helps newbies to perceive the traits in the cryptocurrency
                                                banking
                                                system.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://realstatecoin.org/coinovy-efforts-to-grow-all-around-the-globe/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/img3.jpeg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on October 18, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Coinovy Efforts to grow all
                                                Around the Globe</h3>
                                            <p className="news-subcontent">COINOVY will never compromise on quality or service when it
                                                comes to the consumer experience, as we all know.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://coincome.info/evolution-of-coinovy-and-its-beneficial-features/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/img4.jpeg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on October 18, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Evolution of Coinovy and it’s
                                                Beneficial Features</h3>
                                            <p className="news-subcontent">Blockchain technology, crypto exchanges, wallets, and crypto
                                                to fiat service providers are the four pillars that support the cryptocurrency
                                                ecosystem.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://bitcoin-lawyer.org/coinovy-run-by-a-global-team/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/img1.jpeg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on October 18, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Coinovy – Run by a Global
                                                Team</h3>
                                            <p className="news-subcontent">It’s been incredible to witness the development of
                                                cryptocurrencies and see them take the world by storm in such a short amount of
                                                time.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://bitcoincomlawsuit.info/angel-investors-investing-into-blockchain-and-cryptocurrencies/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/img5.jpeg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on October 18, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Angel Investors Investing
                                                into Blockchain and
                                                Cryptocurrencies</h3>
                                            <p className="news-subcontent">Angel investors are often known as seed investors or private
                                                investors. Angel investors aggressively search out chances to fund entrepreneurs or
                                                start-up businesses.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://cryptnus.com/why-estonia-was-the-best-option-to-start-coinovy/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/img2.jpeg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on October 18, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Why Estonia was the
                                                Best Option to Start Coinovy</h3>
                                            <p className="news-subcontent">COINOVY is a digital banking program that allows you to
                                                transfer, receive, swap, earn, and borrow digital assets from an unlimited
                                                number of
                                                wallets (All-In-One Wallet System).</p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://www.investing.com/news/cryptocurrency-news/understanding-the-fundamentals-of-coinovy-cryptotofiat-2658637" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/Group-431.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on October 28, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Understanding the Fundamentals of Coinovy: Crypto-to-Fiat</h3>
                                            <p className="news-subcontent">Universality remains to be crypto’s greatest characteristic,
                                                but acceptability, ironically, is still largely absent. While we can point out many
                                                reasons for that ...
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://thecryptotown.com/reasons-why-coinovy-is-the-next-big-thing/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/Group-430.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on November 12, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">11 Reasons Why Coinovy is the Next Big Thing in the
                                                Cryptocurrency World</h3>
                                            <p className="news-subcontent">Coinovy is a platform of digital currencies. On this platform
                                                an inexhaustible number of wallets along with digital assets ...
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://www.marketwatch.com/press-release/coinovy-proudly-announces-the-success-of-conducting-presale-round-2021-11-07?tesla=y" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/banner-2-02.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on November 12, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Coinovy Proudly Announces the Success of Conducting Presale
                                                Round</h3>
                                            <p className="news-subcontent">Customers of Coinovy have the convenience of using their very
                                                own crypto ecosystem to make crypto to fiat transactions a breeze.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://cryptoddy.com/crypto-currencies-an-era-of-digital-assets/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/Group-432.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on November 16, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Crypto Currencies: An Era of Digital Assets</h3>
                                            <p className="news-subcontent">In the past few years, crypto ecosystem has grown so rapidly
                                                that it became quite perplexing to understand all its dimensions and types of its
                                                currencies.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://cryptoyish.com/coinovy-the-c2f-token-roadmap/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/Group-433.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on November 16, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Coinovy: The C2F Token Roadmap</h3>
                                            <p className="news-subcontent">The C2F token is a native cryptocurrency for the Coinovy
                                                cryptocurrency ecosystem, an upcoming project that will be an all-encompassing
                                                crypto finance platform.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://coinmarketcap.com/headlines/news/coinovy-bridge-defis-cross-chain-link/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/Group-437.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on November 18, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Coinovy Bridge: DeFi’s Cross-Chain Link</h3>
                                            <p className="news-subcontent">The cryptocurrency market is currently in one of its biggest
                                                bull runs in history. With the prices of Bitcoin and Ether reaching record ...
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://cryptodaily.co.uk/2021/11/coinovy-s-crypto-predictions-could-help-investors-make-better-investments" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/Group-26.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on November 24, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Coinovy’s Crypto Predictions Could Help Investors Make Better
                                                Investments</h3>
                                            <p className="news-subcontent">For an industry just over a decade old, the crypto market has
                                                seen remarkable success, growing from strength to strength every year.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://www.techtimes.com/articles/268661/20211129/a-deep-diver-into-the-rising-crypto-to-fiat-ecosystem.htm" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/image.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on November 29, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">A Deep Diver Into the Rising Crypto to Fiat Ecosystem</h3>
                                            <p className="news-subcontent">One of the major flaws of the global financial system to date
                                                remains its inability to bank the entirety of the population.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <NavLink className="card news-data news-data-2" to="https://www.newsbtc.com/news/company/crypto-reaching-markets-where-banks-cant/" target="_blank">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="news-lists-images">
                                            <img className="news-image" src="../../../assets/images/news/image-1.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="news-body">
                                            <ul className="name-date">
                                                <li className="namedate-content">Published on December 20, 2021.</li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                                <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                            </ul>
                                            <h3 className="news-header">Crypto Reaching Markets Where Banks Can’t</h3>
                                            <p className="news-subcontent">Cryptocurrencies have had an incredible year, surpassing the
                                                $3 trillion mark at one point in 2021.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    {/* ARTICLES ON COINOVY PARTNERSHIP */}
                    <div>
                        <h3 className="news-title news-title1">ARTICLES ON COINOVY PARTNERSHIP</h3>
                        <hr />
                        <div className="row">
                            <div className="col-md-3 mb-4">
                                <NavLink className="card news-second" to="https://www.digitaljournal.com/pr/coinovy-announces-partnership-with-kisakisa" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/news-6.png" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on August 10, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">COINOVY Announces Partnership with KISAKISA</h3>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-3 mb-4">
                                <NavLink className="card news-second" to="https://loop21.com/coinovy-collaborated-with-visa-and-apple-pay-to-provide-you-the-ease-of-payment-mode" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/news-7.png" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on August 19, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">Coinovy Collaborated with Visa and Apple Pay to Provide you the Ease
                                            of
                                            Payment Mode</h3>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-3 mb-4">
                                <NavLink className="card news-second" to="https://enetget.com/2021/08/19/coinovy-collaborates-with-iban-to-accelerates-your-transaction-worldwide/" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/news-8.png" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on August 19, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">Coinovy collaborates with IBAN to accelerates yourtransaction
                                            worldwide
                                        </h3>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-3 mb-4">
                                <NavLink className="card news-second" to="https://btcmanager.com/coinovy-forms-strategic-partnership-with-luna-pr/" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/news-9.png" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on September 1, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">Coinovy forms Strategic Partnership with Luna PR</h3>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 mb-4">
                                <NavLink className="card news-second" to="https://www.cryptoease.io/post/coinovy-partners-with-finx-capital-to-serve-the-unbanked-market-of-southeast-asia" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/news-11.jpeg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on September 29, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">Coinovy Partners with FINX Capital to Serve the
                                            Unbanked Market of Southeast Asia</h3>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-3 mb-4">
                                <NavLink className="card news-second" to="https://beincrypto.com/coinovy-forms-a-strategic-partnership-with-uaba/" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/news-11.jpg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on October 19, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">Coinovy Forms a Strategic Partnership With UABA</h3>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-3 mb-4">
                                <NavLink className="card news-second" to="https://nyheadline.com/press/coinovy-announces-partnership-with-prudent-investment-management-for-managing-token-sales/59171" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/img6.jpg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on November 8, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">Coinovy Announces Partnership With Prudent Investment Management For
                                            Managing Token Sales</h3>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-3 mb-4">
                                <NavLink className="card news-second" to="https://www.marketwatch.com/press-release/coinovy-announces-its-strategic-partnership-with-coinranking-2021-11-09?link=TD_nypost_articles.7c7e0f416376f79f&utm_source=nypost_articles.7c7e0f416376f79f&utm_campaign=circular&utm_medium=MARKETWATCH" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/coinovy&Coinranking-02.jpg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on November 9, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">Coinovy Announces Its Strategic Partnership with Coinranking</h3>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 mb-4">
                                <NavLink className="card news-second" to="https://zexprwire.com/the-partnership-between-coinovy-and-blubitex-community-yields-the-best-opportunities-for-investments/" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/Artboard-4-100.jpg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on November 11, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">The Partnership Between Coinovy and Blubitex Community Yields the
                                            Best Opportunities for Investments</h3>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-3 mb-4">
                                <NavLink className="card news-second" to="https://zexprwire.com/coinovy-and-xprivate-chat-announces-partnership/" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/Artboard-4-101.jpg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on November 20, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">Coinovy and Xprivate Chat Announces Partnership</h3>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-3 mb-4">
                                <NavLink className="card news-second" to="https://markets.financialcontent.com/townhall/news/read/41999175" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/Artboard-4-102.jpg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on November 20, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">COINOVY ANNOUNCES PARTNERSHIP WITH WORLD OF PROFESSIONALS</h3>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <NavLink to="https://medium.com/@coinovy.official" target="_blank" style={{ textDecoration: 'none' }}>
                        <div className="follow-us-se">
                            <div className="row">
                                <div className="col-md-4">
                                    <img className="medium-logo" src="../../../assets/images/news/medium-icon.png" alt="" />
                                </div>
                                <div className="col-md-4">
                                    <p className="followus-txt">Follow us on MEDIUM for daily updates</p>
                                </div>
                                <div className="col-md-4">
                                    <img className="follow-logo" src="../../../assets/images/news/Group-130.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    {/* ARTICLE ON COINOVY TEAM */}
                    <div>
                        <h3 className="news-title news-title1">ARTICLE ON COINOVY TEAM</h3>
                        <hr />
                        <div className="row">
                            <div className="col-md-3 mb-4">
                                <img className="news-second-logo" src="../../../assets/images/media/Group-22.png" alt="" />
                                <NavLink className="card news-second news-second2" to="https://todayheadlinenews.com/crypto/blockchain-specialist-sai-tej-annareddy-ceo-of-chakravuyha-on-his-intruiging-future-plans/" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/news-13.jpeg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on March 18, 2019.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">Blockchain Specialist Sai Tej Annareddy, CEO of Chakravuyha on His
                                            Intruiging Future Plans</h3>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-3 mb-4">
                                <img className="news-second-logo" src="../../../assets/images/media/Group-24.png" alt="" />
                                <NavLink className="card news-second news-second2" to="https://edtimes.in/sai-teja-annareddy-ceo-of-chakravuyha-planning-big-in-blockchain-domain-with-his-formidable-expertise/" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/news-10.jpeg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on April 13, 2019.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">Sai Tej Annareddy, CEO of Chakravuyha Planning Big in Blockchain
                                            Domain
                                            with His Formidable Expertise</h3>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-3 mb-4">
                                <img className="news-second-logo" src="../../../assets/images/media/Group-20.png" alt="" />
                                <NavLink className="card news-second news-second2" to="https://www.deccanherald.com/brandspot/pr-spot/how-sai-tej-annareddy-is-shaping-the-domain-of-blockchain-technology-internationally-1028630.html" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/news-10.jpeg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on September 29, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">How Sai Tej Annareddy is shaping the Domain of Blockchain Technology
                                            Internationally</h3>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-3 mb-4">
                                <img className="news-second-logo" src="../../../assets/images/media/Group-25.png" alt="" />
                                <NavLink className="card news-second news-second2" to="https://www.hindustantimes.com/brand-post/fintech-business-executive-sai-tej-annareddy-s-entrepreneurial-journey-101633006270923.html" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/news-12.jpeg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on Sep 30, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">FinTech business executive Sai Tej Annareddy's entrepreneurial
                                            journey
                                        </h3>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <img className="news-second-logo" src="../../../assets/images/media/Group-23.png" alt="" />
                                <NavLink className="card news-second news-second2" to="https://www.mid-day.com/lifestyle/infotainment/article/blockchain-expert-sai-tej-annareddy-is-revolutionising-the-international-cryptocurrency-space-23194705" target="_blank">
                                    <div className="news-lists-images">
                                        <img className="news-image" src="../../../assets/images/news/news-12.jpeg" alt="" />
                                    </div>
                                    <div className="card-body news-second-body">
                                        <ul className="name-date">
                                            <li className="namedate-content">Published on 01 October, 2021.</li>
                                        </ul>
                                        <ul className="social-icons">
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-1.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-2.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-3.svg" alt="" /></li>
                                            <li className="icon-data"><img className="social-images" src="../../../assets/images/news/social-4.svg" alt="" /></li>
                                        </ul>
                                        <h3 className="news-header">Blockchain Expert Sai Tej Annareddy is Revolutionising the
                                            International
                                            Cryptocurrency Space</h3>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CoinovyMedia;