import React from "react";
import { useTranslation } from 'react-i18next';

const Cards = () => {
    const { t } = useTranslation();
    return (
        <>
            <div>
                {/*  Coinovy digital visa card  */}
                <div className="coinovy-digital-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="coinovy-digital-section">
                                    <h1 className="coinovy-digital-title"> {t('CARD.Coinovy digital')} <br /> Mastercard</h1>
                                    <p className="coinovy-digital-txt">{t('CARD.Ready to')}</p>
                                </div>
                                <div className="coinovy-digital-sub-txt">
                                    <img className="line-img" src="../../../assets/images/lines.svg" alt="" />
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="coinovy-digital-section2">
                                                <img className="coinovy-digital-icon-1" src="../../../assets/images/cards/credit-card-2-1.svg" alt="" />
                                                <h6 className="coinovy-digital-icon-head">{t('CARD.Have your')}</h6>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="coinovy-digital-section2">
                                                <img className="coinovy-digital-icon-1" src="../../../assets/images/cards/worldwide-shipping-1.svg" alt="" />
                                                <h6 className="coinovy-digital-icon-head">{t('CARD.Withdraw from ATMs globally')}</h6>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="coinovy-digital-section2">
                                                <img className="coinovy-digital-icon-1" src="../../../assets/images/cards/dlr-circle.svg" alt="" />
                                                <h6 className="coinovy-digital-icon-head">{t('CARD.Deposit your')}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="coinovy-digital-img">
                                    <img className="coinovy-digital-image" src="../../../../assets/images/cards/pic.png" alt="Coinovy digital Visa card" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* physical card for payment */}
                <div className="physical-card-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="physical-card-img-sec">
                                    <img className="physical-card-image" src="../../../assets/images/cards/physical-card.svg" alt="Coinovy card for shopping" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="physical-card-text-section">
                                    <ul className="physical-card">
                                        <li><img className="physical-card-line" src="../../../assets/images/Rectangle-64.svg" alt="" /></li>
                                        <li>
                                            <h1 className="physical-card-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                                {t('CARD.PHYSICAL CARD')}

                                            </h1>
                                            <h1 className="physical-card-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                                {t('CARD.PAYMENT AND SHOPPING')}

                                            </h1>
                                        </li>
                                    </ul>
                                    <div className="physical-card-sec2">
                                        <h6 className="physical-card-sec2-txt" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1100} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false"><span><img className="physical-card-sec2-img" src="../../../assets/images/cards/shopping-bag-2.svg" alt="" />
                                        </span>{t('CARD.HASSLE-FREE SHOPPING')}</h6>
                                        <p className="physical-card-sec2-subtxt">
                                            {t('CARD.Insert, swipe')}

                                        </p>
                                    </div>
                                    <div className="physical-card-sec2">
                                        <h6 className="physical-card-sec2-txt" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1100} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false"><span><img className="physical-card-sec2-img" src="../../../assets/images/cards/atm3.svg" alt="" /> </span>

                                            {t('CARD.FAST WITHDRAWAL')}
                                        </h6>
                                        <p className="physical-card-sec2-subtxt">
                                            {t('CARD.Need instant')}

                                        </p>
                                    </div>
                                    <div className="physical-card-sec2">
                                        <h6 className="physical-card-sec2-txt" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1100} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false"><span><img className="physical-card-sec2-img" src="../../../assets/images/cards/credit-card-2-1.svg" alt="" />
                                        </span> {t('CARD.PHYSICAL CARD FEES')}</h6>
                                        <p className="physical-card-sec2-subtxt">
                                            {t('CARD.Apply for')}

                                        </p>
                                    </div>
                                    <img className="physical-card-sec-line" src="../../../assets/images/cards/lines.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* simple steps */}
                <div className="simple-steps-content">
                    <div className="container">
                        <div className="steps-data">
                            <h3 className="steps-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                {t('CARD.5 SIMPLE STEPS')} <span>{t('CARD.TO GET')}</span>{t('CARD.YOUR CARD')}</h3>
                            {/* <img className="cards-line-image" src="/../../../assets/images/loan/lines.svg" alt="" /> */}
                            <p className="steps-subtitel">{t('CARD.DON’T WAIT, GO DIGITAL!')}</p>
                            <div className="row justify-content-center">
                                <div className="col-md-2">
                                    <div className="card steps-boxes-all">
                                        <div className="steps-images">
                                            <img className="card-img-top steps-box-images" src="/../../../assets/images/cards/steps-first.svg" alt="Card cap" />
                                        </div>
                                        <div className="card-body steps-box-data">
                                            <h3 className="card-title steps-boxs-title">{t('CARD.Step 1')}</h3>
                                            <p className="card-text steps-boxs-subtitle">{t('CARD.Create your')}<br />
                                                {t('CARD.Wallet account')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="card steps-boxes-all">
                                        <div className="steps-images">
                                            <img className="card-img-top steps-box-images" src="/../../../assets/images/cards/visiting-card.svg" alt="Card cap" />
                                        </div>
                                        <div className="card-body steps-box-data">
                                            <h3 className="card-title steps-boxs-title">{t('CARD.Step 2')}</h3>
                                            <p className="card-text steps-boxs-subtitle">{t('CARD.Choose your')}
                                                <br />{t('CARD.card type from the')}
                                                <br />{t('CARD.options.')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="card steps-boxes-all">
                                        <div className="steps-images">
                                            <img className="card-img-top steps-box-images" src="/../../../assets/images/cards/analytics.svg" alt="Card cap" />
                                        </div>
                                        <div className="card-body steps-box-data">
                                            <h3 className="card-title steps-boxs-title">{t('CARD.Step 3')}</h3>
                                            <p className="card-text steps-boxs-subtitle">{t('CARD.Step 2')}<br />
                                                {t('CARD.credentials and pay')} <br />{t('CARD.the delivery fee.')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="card steps-boxes-all">
                                        <div className="steps-images">
                                            <img className="card-img-top steps-box-images" src="/../../../assets/images/cards/steps-box-images-1.svg" alt="Card cap" />
                                        </div>
                                        <div className="card-body steps-box-data">
                                            <h3 className="card-title steps-boxs-title">{t('CARD.Step 4')}</h3>
                                            <p className="card-text steps-boxs-subtitle">{t('CARD.Get verified')}<br />
                                                {t('CARD.approved in less')}<br />
                                                {t('CARD.than an hour.')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="card steps-boxes-all">
                                        <div className="steps-images">
                                            <img className="card-img-top steps-box-images" src="/../../../assets/images/cards/worldwide-shipping.svg" alt="Card cap" />
                                        </div>
                                        <div className="card-body steps-box-data">
                                            <h3 className="card-title steps-boxs-title">{t('CARD.Step 5')}</h3>
                                            <p className="card-text steps-boxs-subtitle">{t('CARD.Activate your')}
                                                <br />{t('CARD.and start using it!')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="get-cards">
                                <p className="get-caards-content">
                                    {t('CARD.Don’t want')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* cards-loans */}
                <div className="cards-interest-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="cards-interest-section">
                                    {/* <h1 class="cards-interest-title">Low - interest crypto <br>loans in minutes</h1> */}
                                    <ul className="cards-featured-total">
                                        <li><img className="cards-featured-line" src="../../../assets/images/loan/vertical-line.svg" alt="" />
                                        </li>
                                        <li>
                                            <h1 className="cards-featured-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('CARD.GO VIRTUAL')}<span>
                                                {t('CARD.PROTECT')}</span> <br />{t('CARD.YOUR MONEY')}
                                            </h1>
                                        </li>
                                    </ul>
                                    <p className="cards-interest-subcontent">{t('CARD.The Coinovy')}</p>
                                    {/* <a href=""><button class="cards-interest-btn">{{'CARD.Get your plan' | translate}}</button></a> */}
                                </div>
                                <div className="cards-loancontent">
                                    <div className="cards-transactions-mainall">
                                        <img className="cards-line-image" src="/../../../assets/images/loan/lines.svg" alt="" />
                                        <ul className="cards-transaction">
                                            <li><img className="cards-img" src="/../../../assets/images/loan/pie-chart-2.svg" alt="" />
                                                <h3 className="cards-title">{t('CARD.Block & Unlock with ease')}</h3>
                                            </li>
                                            <li><img className="cards-img" src="/../../../assets/images/loan/pie-chart-5.svg" alt="" />
                                                <h3 className="cards-title">{t('CARD.24/7 customer support')}</h3>
                                            </li>
                                            <li><img className="cards-img" src="/../../../assets/images/loan/pie-chart-3.svg" alt="" />
                                                <h3 className="cards-title">{t('CARD.2FA Authorization')}</h3>
                                            </li>
                                            <li><img className="cards-img" src="/../../../assets/images/loan/pie-chart-1.svg" alt="" />
                                                <h3 className="cards-title">{t('CARD.Touch & Face ID')}</h3>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="cards-contenloan">
                                    <img className="cards-book" src="../../../../assets/images/cards/protect-image.png" alt="Coinovy wallet Security, Use the lock feature from the Coinvoy wallet to control your debit card." data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* full-guide everyting about crypto */}
                <div className="cardsfull-guide-crypto">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <h3 className="cardsfull-guide-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                    A<span>{t('CARD.FULL GUIDE')}</span> {t('CARD.TO YOUR COINOVY CARD IS HERE')}
                                </h3>
                                <p className="cardsfull-guide-subtitle">
                                    {t('CARD.Learn the')}
                                </p>
                            </div>
                        </div>
                        <div className="cardsfull-guide-index">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="media cardsfull-guide-crypto-content">
                                        <div className="cardsfull-guide-images"> <img className="cardshelp-image" src="/../../../assets/images/loan/help-center.svg" alt="Generic placeholder" />
                                        </div>
                                        <div className="media-body ml-4">
                                            <h5 className="cardsfull-guide-crypto-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('CARD.HELP CENTER')}</h5>
                                            <p className="cardsfull-guide-crypto-subtitle">{t('CARD.Dont let')}</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="media cardsfull-guide-crypto-content">
                                        <div className="cardsfull-guide-images"> <img className="help-image" src="/../../../assets/images/loan/blog.svg" alt="" />
                                        </div>
                                        <div className="media-body ml-4">
                                            <h5 className="cardsfull-guide-crypto-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('CARD.BLOG')}BLOG</h5>
                                            <p className="cardsfull-guide-crypto-subtitle">{t('CARD.Reinforce your')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="media cardsfull-guide-crypto-content">
                                        <div className="cardsfull-guide-images"> <img className="help-image" src="/../../../assets/images/loan/analytics.svg" alt="" />
                                        </div>
                                        <div className="media-body ml-4">
                                            <h5 className="cardsfull-guide-crypto-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('CARD.ANALYTICS')}</h5>
                                            <p className="cardsfull-guide-crypto-subtitle">{t('CARD.Find out')}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="line-image"> */}
                            <img className="cards-line-img" src="/../../../assets/images/loan/lines.svg" alt="" />
                            <img className="cards-line-img1" src="/../../../assets/images/loan/lines.svg" alt="" />
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                {/* Coinovy products */}
                <div className="cards-products-community">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="cards-products-right-image">
                                    <img className="cards-products-image" src="/../../../assets/images/loan/product-image-left.svg" alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="cards-products-allmain">
                                    <ul className="cards-products-total">
                                        <li><img className="cards-products-line" src="../../../assets/images/loan/vertical-line.svg" alt="" />
                                        </li>
                                        <li>
                                            <h1 className="cards-products-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('CARD.COINOVY PRODUCTS')}</h1>
                                        </li>
                                    </ul>
                                    <p className="cards-products-subcontent">
                                        {t('CARD.Looking for')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* cards--security */}
                <div className="cards-security-content">
                    <div className="cards-image-background">
                        <div className="container">
                            <div className="cards-data">
                                <img className="cards-line-image" src="/../../../assets/images/loan/lines.svg" alt="" />
                                <img className="cards-line-image1" src="/../../../assets/images/loan/lines.svg" alt="" />
                                <div className="row justify-content-center">
                                    <ul className="cards-security-list">
                                        <li>
                                            <div className="card cards-ways">
                                                <img className="card-img-top cards-image" src="/../../../assets/images/loan/pie-chart-1.svg" alt="" />
                                                <div className="card-body">
                                                    <h5 className="card-title cards-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('CARD.CRYPTO')}<br />
                                                        {t('CARD.WALLET')}  </h5>
                                                    <p className="card-text cards-subtitle">{t('CARD.Monitor your')}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="card cards-ways">
                                                <img className="card-img-top cards-image" src="/../../../assets/images/loan/pie-chart-2.svg" alt="Card cap" />
                                                <div className="card-body pl-0">
                                                    <h5 className="card-title cards-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('CARD.EARNING')}<br />
                                                        {t('CARD.DEPOSITS')}</h5>
                                                    <p className="card-text cards-subtitle">{t('CARD.Need more')}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="card cards-ways">
                                                <img className="card-img-top cards-image" src="/../../../assets/images/loan/pie-chart-3.svg" alt="" />
                                                <div className="card-body pl-0">
                                                    <h5 className="card-title cards-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('CARD.AI-BASED PRICE')}<br /> {t('CARD.PREDICTIONS')}</h5>
                                                    <p className="card-text cards-subtitle">{t('CARD.Let our')}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="card cards-ways">
                                                <img className="card-img-top cards-image" src="/../../../assets/images/loan/pie-chart-4.svg" alt="" />
                                                <div className="card-body pl-0">
                                                    <h5 className="card-title cards-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('CARD.LOW - INTEREST')}<br />LOANS</h5>
                                                    <p className="card-text cards-subtitle">{t('CARD.Need crypto')}Need crypto for your blockchain ventures? Get it with us and pay only minimal loans fees.</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="card cards-ways">
                                                <img className="card-img-top cards-image" src="/../../../assets/images/loan/pie-chart-5.svg" alt="Card cap" />
                                                <div className="card-body pl-0">
                                                    <h5 className="card-title cards-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('CARD.PRE - ARRANGED')} <br />{t('CARD.PORTFOLIOS')} PORTFOLIOS </h5>
                                                    <p className="card-text cards-subtitle">
                                                        {t('CARD.Grow your')}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Cards;