import React from "react";

const CryptoMarket = () => {
    return (
        <>
            <div>
                <div className="cryptomarket-content">
                    <img className="cryptomarket-img" src="../../../assets/images/blog/cryptomarket-bg.png" alt="" />
                </div>
                <div className="cryptomarket-data">
                    <div className="container">
                        <h3 className="cryptomarket-title">How the Pandemic Affected the Crypto Market </h3>
                        <p className="cryptomarket-subtitle">The COVID-19 pandemic has undoubtedly changed numerous things worldwide since
                            it first broke out – from people’s daily routines to the global economy. With lockdowns and various health
                            protocols set in place, many industries have suffered
                            incredible losses, with some only beginning to recover thanks to the continuous vaccine roll-out. However,
                            one market proved to be resilient enough to adapt to the extreme changes brought upon by the pandemic and
                            even thrived during these
                            challenging times – the cryptocurrency market. Still, how did the pandemic affect the crypto market?
                        </p>
                        <h3 className="cryptomarket-title">Positive Changes in Cryptocurrencies During the Pandemic </h3>
                        <p className="cryptomarket-subtitle"> It’s no secret that the cryptocurrency market is a volatile one, with prices
                            potentially changing at a moment’s notice.
                        </p>
                        <p className="cryptomarket-subtitle"> Despite this, the crypto market experienced a surge in interested investors
                            during the pandemic. This was especially true when big industry names, such as Elon Musk and Jack Dorsey,
                            expressed their support for cryptocurrencies like Bitcoin.
                        </p>
                        <p className="cryptomarket-subtitle"> In fact, Bitcoin was valued at more than 60,000 USD during the early months of
                            2021 due to these events, with around 13% of Americans investing in crypto in the past year. Aside from
                            this, the crypto market enjoyed immense growth in recent
                            years since the pandemic has forced most people to stay at home, increasing the need for easily accessible
                            high-speed internet connections. As a result, it created an environment conducive to cryptocurrency
                            investments.
                        </p>
                        <h3 className="cryptomarket-title">Risks of Investing in Crypto During the Pandemic </h3>
                        <p className="cryptomarket-subtitle">Of course, despite the sudden rise of crypto investments, it can be risky to
                            start investing in digital currencies during the pandemic, especially since the crypto market remains highly
                            volatile and the values tend to change rapidly.
                        </p>
                        <p className="cryptomarket-subtitle">Because of this, experts believe that the spike in prices of popular
                            cryptocurrencies like Bitcoin isn’t a long-term thing. In addition, during times of heightened uncertainties
                            like a pandemic, experiencing losses from investments can be
                            more devastating.
                        </p>
                        <p className="cryptomarket-subtitle">Aside from this, there has been a significant increase in
                            cryptocurrency-related crimes, especially during the first few months of the pandemic.
                        </p>
                        <p className="cryptomarket-subtitle">Overall, the pandemic has positively affected the crypto market despite
                            negatively affecting the other industries. Of course, while there have been significant surges in the value
                            of cryptocurrencies like Bitcoin, it’s still best to be prudent
                            when it comes to crypto investments. This is especially true during times of increased uncertainty, like
                            during a global pandemic.
                        </p>
                    </div>
                </div>
            </div>

        </>
    )
}
export default CryptoMarket;