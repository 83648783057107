import React from "react";

const BegineerGuide = () => {
    return (
        <>
            <div>
                <div className="begineer-image">
                    <div className="container">
                        <h3 className="title-begineer">Begineer Guide to Cryptocurrency</h3>
                    </div>
                </div>
                <div className="begineers-data">
                    <div className="container">
                        <p className="begin-steps1">
                            As more people show interest in joining the crypto industry, a common struggle among novices is on where and
                            how to start. As many might have noticed, a majority of crypto investors are already well-versed in the
                            field as though they were born to do this.
                            Because of this, it can be extremely intimidating for beginners to even ask what it is about. However, it is
                            important to remember that these people were not born crypto experts. These people have diligently
                            sacrificed time and effort
                            to study every nook and corner of the field before getting involved.For beginners who want to venture into
                            cryptocurrency trading, then the first step is to know the basics. In this article, we'll be discussing how
                            Cryptocurrency came
                            to be and how it works.</p>
                        <p className="begin-titles">History</p>
                        <p className="begin-steps1">
                            Cryptocurrency is a recent trend that started in the past decade. Despite that, in only a few years it
                            gained massive attention from global investors for its rapidly rising value. The mastermind of this idea is
                            Satoshi Nakamoto, whose identity is unknown
                            until now. In 2008, they published a paper introducing a decentralized network that paved the way for the
                            creation of Bitcoin. The journey started slowly, but eventually more cryptocurrencies like Bitcoin were
                            launched a few years later.
                            By 2014, there were dozens of cryptocurrencies available, some thrived and some failed right when they
                            started. This system, despite being highly volatile, gained a massive amount of followers as soon as the
                            year 2017 rolled in. It was
                            hailed the public’s ‘savior’ from the corrupt system of finances. It continued to gain popularity for its
                            flexibility. More cryptocurrencies were made, and values started to rise steadily.</p>
                        <p className="begin-titles">What Is Cryptocurrency</p>
                        <div className="row ">
                            {/* <div className="media crypto-image"> */}
                            <div className="col-md-5">
                                <img className="image-content" src="../../../assets/images/blog/begining-image1.png" alt="" />
                            </div>
                            <div className="col-md-7">
                                <div className="media-body key-features-main">
                                    <p className="begin-steps1">Cryptocurrency is digital money. What sets it apart is that it uses cryptography
                                        to keep it secure and decentralized. This means that instead of a bank or government, it operates
                                        with a distributed ledger for equal authority. Cryptocurrency’s
                                        monetary policy is fixed in terms of token limits.</p>
                                    <h5 className="begin-titles mt-0">Media heading</h5>
                                    <p className="begin-steps1">What makes cryptocurrency so popular?</p>
                                    <p className="begin-steps1">Cryptocurrency is digital money. What sets it apart is that it uses cryptography
                                        to keep it secure and decentralized. This means that instead of a bank or government, it operates
                                        with a distributed ledger for equal authority. Cryptocurrency’s
                                        monetary policy is fixed in terms of token limits.</p>
                                    <ol className="key-features">
                                        <li>It is portable and easy to transport since it is a digital currency.</li>
                                        <li>It can be divided into smaller amounts conveniently </li>
                                        <li>It is prevalent in today’s society and is rich in future supply </li>
                                        <li>It is secure </li>
                                        <li>The government or regime may censor its purpose</li>
                                    </ol>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="row ">
                            <div className="col-md-8 media-body">
                                <div className="media crypto-image">
                                    <h5 className="begin-titles mt-0 mb-1">How It Works</h5>
                                    <p className="begin-steps1">The whole process starts with the ledger. The ledger is responsible for holding
                                        all transactions in the crypto industry, also known as the ‘blockchain’. All information received is
                                        timestamped, posted, and verified by other stakeholders
                                        to confirm. Additionally, a small fee is required for every transaction to keep the security. As for
                                        how cheap it is, a $99 million Litecoin transfer will only cost you $0.40 which is far cheaper
                                        compared to centralized platforms.
                                        <br />To visualize, let’s say that you want to transfer Bitcoin to your spouse. So what happens is
                                        that you create a transaction and send a request to transfer Bitcoin to your spouse’s account at a
                                        small fee. This transaction will
                                        then be held together with other transactions and as a whole, this becomes a Bitcoin blockchain. The
                                        ledgers will then go through everything and verify your transaction.
                                    </p>
                                </div>
                            </div>
                            <img className="col-md-4 image-content1 ml-3" src="../../../assets/images/blog/Nuthawut-image.png" alt="" />
                        </div>
                        <p className="begin-titles mb-2">Uses of Cryptocurrency</p>
                        <p className="begin-titles mb-2">Here are some of the many ways to benefit from cryptocurrency:</p>
                        <ul className="benifits-content">
                            <li>Transfer money at low fees. Earn interest through crypto trading </li>
                            <li>The system is censorship-resistant </li>
                            <li>Offers innovative start-up ventures for beginners </li>
                            <li>Private transactions are possible </li>
                            <li>Non-cash transactions are possible</li>
                        </ul>
                        <p className="begin-steps1">That completes the quick beginner’s guide on Cryptocurrency. Right now you should have a
                            solid idea of what it is all about. However, remember that the entire process and industry cannot be learned
                            in a few minutes. If you wish to learn more
                            about the crypto market, then you would need to go through a lot more readings and research. It would also
                            be a great idea to hear from seasoned investors as well.
                        </p>
                        <p className="begin-steps1"> Remember that cryptocurrency is volatile, which means that it holds as many risks as it
                            does potential. Thus, if you are the type of person who cannot afford losses, then this platform may not fit
                            you, as getting involved means being ready
                            for losses. It's not a great source of income, but it is a good source of investment if you know what you're
                            doing.
                        </p>
                    </div>
                </div>
            </div>

        </>
    )
}
export default BegineerGuide;