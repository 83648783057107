import React from "react";

const BitcoinMining = () => {
    return (
        <>
            <div>
                <div className="mining-content">
                    <img className="mining-img" src="../../../assets/images/blog/bitcoin-mining.png" alt="" />
                </div>
                <div className="mining-data">
                    <div className="container">
                        <h3 className="mining-title">Bitcoin Mining: How Does It Work?</h3>
                        <p className="mining-subtitle">The world is suddenly going crazy with Bitcoin. Cryptocurrency jargon has been popping up out of nowhere. Like most people, it can be confusing when they hear Bitcoin Mining. But how exactly can you mine with cryptocurrencies? Turns out it’s
                            harder than you think.</p>
                        <h3 className="mining-title">What is Bitcoin Mining?</h3>
                        <p className="mining-subtitle">Before we look into how to mine Bitcoins, let’s take a quick overview of what it is.
                            <br />To put it simply, Bitcoin minings is the process where new Bitcoins are being fed into circulation. It’s one of the ways you can earn ‘free’ Bitcoin.</p>
                        <h3 className="mining-title">How does Bitcoin Mining Work?</h3>
                        <p className="mining-subtitle"> Bitcoins rely on blockchains to operate efficiently. Think of it as a block loaded with chained data. To mine Bitcoins, you’ll have to add blocks to the blockchain. Each completed block will be verified before you can receive your reward in
                            Bitcoins.
                        </p>
                        <h3 className="mining-title mining-title1">But here’s the catch -- you need to pass these 2 verification conditions:</h3>
                        <p className="mining-subtitle">1. The transaction should be 1 megabyte.
                        </p>
                        <p className="mining-subtitle">2. You have to go through the process called Proof of Work. This means that you should be the first one to solve the number problem and pass the correct answer. If not, the closest. It’s a competition between miners. You have to solve complex
                            math problems as quick as you can
                        </p>
                        <h3 className="mining-title"> What Types of Math Problems are Involved?</h3>
                        <p className="mining-subtitle">Miners have to come up with a hash. This is a hexadecimal number comprised of 64 digits. You’ll need to guess all the possible 64 digits until you find the right solution. Finding the right algorithm requires a lot of trial and error. That’s
                            why solving for one consumes too much time and energy.</p>
                        <h3 className="mining-title">Final Thoughts</h3>
                        <p className="mining-subtitle"> Bitcoin mining may seem easy but it requires heavy computation and patience. There’s a lot of risks involved too. If you were able to solve it but someone was verified first, then all that work will be for nothing. The problems increase the
                            difficulty the more miners there are. However, the harder the problems become, the more expensive than new block becomes. To those kids who hated solving math problems in class, turns out solving complex equation can help you get ‘free'
                            money.
                        </p>
                    </div>
                </div>
            </div>

        </>
    )
}
export default BitcoinMining;