import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Help = () => {
    const location = useLocation();


    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, []);
    return (
        <>
            <div className="faq-main-section">
                <div className="container">

                    <iframe src="https://admin.vault.ist/iframe/faq/11189787857692/11274316198556" frameborder="0" width="100%" height="600px" />

                    {/* <div className="row">
                        <div className="col-md-3">
                            <ul className="nav nav-pills mb-3 custm-nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item custm-nav-li">
                                    <NavLink className="nav-link active custm-nav-a" id="What-is-a-tab" data-toggle="pill" href="#What-is-a" role="tab" aria-controls="What-is-a" aria-selected="true">What is a cashout?</NavLink>
                                </li>
                                <li className="nav-item custm-nav-li">
                                    <NavLink className="nav-link custm-nav-a" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">How to cash out my funds?</NavLink>
                                </li>
                                <li className="nav-item custm-nav-li">
                                    <a className="nav-link custm-nav-a" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">What cards are accepted?</a>
                                </li>
                                <li className="nav-item custm-nav-li">
                                    <a className="nav-link custm-nav-a" id="How-many-cards-tab" data-toggle="pill" href="#How-many-cards" role="tab" aria-controls="How-many-cards" aria-selected="false">How
                                        many cards can I link for a cashout?</a>
                                </li>
                                <li className="nav-item custm-nav-li">
                                    <a className="nav-link custm-nav-a" id="How-long-does-tab" data-toggle="pill" href="#How-long-does" role="tab" aria-controls="How-long-does" aria-selected="false">How long does it take?</a>
                                </li>
                                <li className="nav-item custm-nav-li">
                                    <a className="nav-link custm-nav-a" id="Supported-countries-tab" data-toggle="pill" href="#Supported-countries" role="tab" aria-controls="Supported-countries" aria-selected="false">Supported countries (MasterCard &amp; Visa cashouts)</a>
                                </li>
                                <li className="nav-item custm-nav-li">
                                    <a className="nav-link custm-nav-a" id="What-are-the-tab" data-toggle="pill" href="#What-are-the" role="tab" aria-controls="What-are-the" aria-selected="false">What are the fees?</a>
                                </li>
                                <li className="nav-item custm-nav-li">
                                    <a className="nav-link custm-nav-a" id="How-to-withdraw-tab" data-toggle="pill" href="#How-to-withdraw" role="tab" aria-controls="How-to-withdraw" aria-selected="false">How
                                        to withdraw funds to my bank account?</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-9">
                            <div>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="What-is-a" role="tabpanel" aria-labelledby="What-is-a-tab">
                                        <div>
                                            <h4 className="faq-question">What is a cashout?</h4>
                                            <div className="media" style={{ float: 'left' }}>
                                                <img className="mr-3 faq-profile-img" src="../../../assets/images/faq/profile-img.png" alt="" />
                                                <div className="media-body">
                                                    <p className="faq-profile-txt">Customer Care Management Team<br />Updated</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="faq-box" style={{ borderBottom: '1px solid #DFDFDF' }}>
                                            <p className="faq-box-txt">The cashout is an option to withdraw your crypto to your card
                                                balance as a fiat money.</p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div>
                                            <h4 className="faq-question">How to cash out my funds?</h4>
                                            <div className="media" style={{ float: 'left' }}>
                                                <img className="mr-3 faq-profile-img" src="../../../assets/images/faq/profile-img.png" alt="" />
                                                <div className="media-body">
                                                    <p className="faq-profile-txt">Customer Care Management Team<br />Updated</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="faq-box">
                                            <p className="faq-box-txt">Make sure you are on the main <span>Dashboard</span> view.</p>
                                            <img className="faq-box-img" src="../../../assets/images/faq/Group-631.svg" alt="" />
                                        </div>
                                        <div className="faq-box faq-box2">
                                            <p className="faq-box-txt">Tap on the <span>Cash out</span> icon in the upper right corner.
                                            </p>
                                            <img className="faq-box-img" src="../../../assets/images/faq/Group-632.png" alt="" />
                                        </div>
                                        <div className="faq-box faq-box3">
                                            <p className="faq-box-txt">You will see 2 options there: <span>To card</span> and <span>To
                                                bank account. </span>Please, select the <span>To card </span>option..</p>
                                            <img className="faq-box-img" src="../../../assets/images/faq/Group-646.png" alt="" />
                                        </div>
                                        <div className="faq-box faq-box3">
                                            <p className="faq-box-txt">The upper part of the <span>Cash out </span>tab has 2 fields that
                                                can be
                                                modified: <span>From </span>and <span>To. </span>There, you need to select the
                                                cryptocurrency which you would
                                                like to convert to fiat money and the card, where you want to credit it.</p>
                                            <img className="faq-box-img" src="../../../assets/images/faq/Image-1.png" alt="" />
                                        </div>
                                        <div className="faq-box faq-box3">
                                            <p className="faq-box-txt">To proceed with the withdrawal, please tap on the <span>From
                                            </span>field and
                                                select the crypto of interest. The system will show you the amount of crypto you
                                                have, so you can easily see, what amount of crypto can be exchanged.</p>
                                            <img className="faq-box-img" src="../../../assets/images/faq/mceclip6.png" alt="" />
                                        </div>
                                        <div className="faq-box faq-box3">
                                            <p className="faq-box-txt">Click on the To field and fill in the card data to define the
                                                withdrawal destination account.</p>
                                            <img className="faq-box-img" src="../../../assets/images/faq/mceclip7.png" alt="" />
                                        </div>
                                        <div className="faq-box faq-box3">
                                            <p className="faq-box-txt">As soon as you have introduced the value and the card data, the
                                                platform will block the transaction and give you a few seconds to check its details.
                                                If everything is fine, please confirm the withdrawal by pressing the <span>Get
                                                </span>... button.
                                            </p>
                                            <img className="faq-box-img" src="../../../assets/images/faq/mceclip9.png" alt="" />
                                        </div>
                                        <div className="faq-box faq-box3">
                                            <p className="faq-box-txt">Normally, the transaction is performed immediately. In some
                                                cases, it might take up to 30 minutes for the system to successfully handle it. Once
                                                the status of the withdrawal is changed to Success, it means that your funds have
                                                been sent to your bank.</p>
                                            <img className="faq-box-img" src="../../../assets/images/faq/mceclip10.png" alt="" />
                                            <p className="faq-box-txt faq-box-txt2"><span>Note: </span>The final time of your funds to
                                                be credited to
                                                your card balance depends on your bank and may take up to a few working days.</p>
                                            <p className="faq-box-txt faq-box-txt3">If that doesn’t help, please reach us at <span>support@coinovy.com</span></p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                        <div>
                                            <h4 className="faq-question">What cards are accepted?</h4>
                                            <div className="media" style={{ float: 'left' }}>
                                                <img className="mr-3 faq-profile-img" src="../../../assets/images/faq/profile-img.png" alt="" />
                                                <div className="media-body">
                                                    <p className="faq-profile-txt">Customer Care Management Team<br />Updated</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="faq-box" style={{ borderBottom: '1px solid #DFDFDF' }}>
                                            <p className="faq-box-txt">When filling the data for a cashout request, please make sure
                                                that you:</p>
                                            <ul>
                                                <li className="faq-box-de">using your card, it has your name listed as a cardholder;
                                                </li>
                                                <li className="faq-box-de">entering the correct card information: number, cardholder
                                                    name, CVV code, and expiry date.</li>
                                                <li className="faq-box-de">using a debit card. Many banks do not work with crypto,
                                                    considering it as risky operations, and refuse to conduct such transactions.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="How-many-cards" role="tabpanel" aria-labelledby="How-many-cards-tab">
                                        <div>
                                            <h4 className="faq-question">How many cards can I link for a cashout?</h4>
                                            <div className="media" style={{ float: 'left' }}>
                                                <img className="mr-3 faq-profile-img" src="../../../assets/images/faq/profile-img.png" alt="" />
                                                <div className="media-body">
                                                    <p className="faq-profile-txt">Customer Care Management Team<br />Updated</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="faq-box" style={{ borderBottom: '1px solid #DFDFDF' }}>
                                            <p className="faq-box-de">You can link up to 4 cards for a cashout.
                                            </p>
                                            <p className="faq-box-de">
                                                Please note, that these
                                                cards will be also automatically linked to the PayIn option.
                                            </p>
                                            <p className="faq-box-de">
                                                If you would like to
                                                delete some of your bank cards, linked to your Crypterium account, please reach out
                                                to our Support Team.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="How-long-does" role="tabpanel" aria-labelledby="How-long-does-tab">
                                        <div>
                                            <h4 className="faq-question">How long does it take?</h4>
                                            <div className="media" style={{ float: 'left' }}>
                                                <img className="mr-3 faq-profile-img" src="../../../assets/images/faq/profile-img.png" alt="" />
                                                <div className="media-body">
                                                    <p className="faq-profile-txt">Customer Care Management Team<br />Updated</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="faq-box" style={{ borderBottom: '1px solid #DFDFDF' }}>
                                            <p className="faq-box-txt">
                                                All the payouts normally are performed by us instantly, however, it might take up to
                                                30 minutes for the system to successfully handle it. Once the status of the
                                                withdrawal is changed to Success, it means that your funds have been sent to your
                                                bank.
                                            </p>
                                            <p className="faq-box-txt">
                                                Still, the final time of your funds to be credited to your card balance
                                                depends on your bank and may take up to a few working days.
                                            </p>
                                            <p className="faq-box-note">
                                                <span>Note: </span>In some cases, our billing team may need additional approval from
                                                your side to guarantee the security of your request. Thus, if you create a
                                                withdrawal request, please, check your email once in a while to make sure that you
                                                are not missing a piece of information.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Supported-countries" role="tabpanel" aria-labelledby="Supported-countries-tab">
                                        <div>
                                            <h4 className="faq-question">Supported countries (MasterCard &amp; Visa cashouts)</h4>
                                            <div className="media" style={{ float: 'left' }}>
                                                <img className="mr-3 faq-profile-img" src="../../../assets/images/faq/profile-img.png" alt="" />
                                                <div className="media-body">
                                                    <p className="faq-profile-txt">Customer Care Management Team<br />Updated</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="faq-box" style={{ borderBottom: '1px solid #DFDFDF' }}>
                                            <p className="faq-box-txt">
                                                We are constantly working on increasing the number of countries, where cashout is
                                                processed fast and smooth.
                                            </p>
                                            <p className="faq-box-de">
                                                However, still, some countries may experience
                                                difficulties with this method. Please, check the countries list to see those, where
                                                you can use cashout:
                                            </p>
                                            <div>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr style={{ textAlign: 'center' }}>
                                                            <th scope="col" />
                                                            <th scope="col" className="tbl-head">Master Card</th>
                                                            <th scope="col" className="tbl-head">Visa</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">AUSTRI</th>
                                                            <td style={{ textAlign: 'center', fontSize: '14px' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">BELGIUM</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">BULGARIA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">CANADA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">CROATIA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">CYPRUS</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">CZECH REPUBLIC</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">DENMARK</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">ESTONIA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">FINLAND</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">FRANCE</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">GERMANY</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">GIBRALTAR</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">GREECE</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">HUNGARY</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">ICELAND</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">INDIA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">IRELAND</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">ITALY</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">JAPAN</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">LATVIA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">LIECHTENSTEIN</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">LITHUANIA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">LUXEMBOURG</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">MALAYSIA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">MALTA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">MEXICO</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">NETHERLANDS</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">NORWAY</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">POLAND</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">PORTUGAL</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">ROMANIA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">RUSSIAN FEDERATION</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">SLOVAKIA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">SLOVENIA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">SOUTH AFRICA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">SPAIN</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">SWEDEN</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">SWITZERLAND</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">TAIWAN</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">THAILAND</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">UNITED KINGDOM</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row" className="tbl-txt">USA</th>
                                                            <td style={{ textAlign: 'center' }}>+</td>
                                                            <td style={{ textAlign: 'center' }} />
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p className="faq-box-de">Even if your country doesn't support bank card withdrawals, you
                                                can send crypto to an
                                                external wallet for the exchange or use our Crypterium card to spend it.</p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="What-are-the" role="tabpanel" aria-labelledby="What-are-the-tab">
                                        <div>
                                            <h4 className="faq-question">What are the fees?</h4>
                                            <div className="media" style={{ float: 'left' }}>
                                                <img className="mr-3 faq-profile-img" src="../../../assets/images/faq/profile-img.png" alt="" />
                                                <div className="media-body">
                                                    <p className="faq-profile-txt">Customer Care Management Team<br />Updated</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="faq-box" style={{ borderBottom: '1px solid #DFDFDF' }}>
                                            <p className="faq-box-txt">We charge €2,5 for a withdrawal (that’s a fixed part) and an
                                                additional 2,5% from the whole transaction amount.</p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="How-to-withdraw" role="tabpanel" aria-labelledby="How-to-withdraw-tab">
                                        <div>
                                            <h4 className="faq-question">How to withdraw funds to my bank account?</h4>
                                            <div className="media" style={{ float: 'left' }}>
                                                <img className="mr-3 faq-profile-img" src="../../../assets/images/faq/profile-img.png" alt="" />
                                                <div className="media-body">
                                                    <p className="faq-profile-txt">Customer Care Management Team<br />Updated</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="faq-box" style={{ borderBottom: '1px solid #DFDFDF' }}>
                                            <p className="faq-box-txt">At the moment, the option to withdraw funds to your bank account
                                                is unavailable. Please stay tuned to be the first one who gets this update!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        </>
    )
}

export default Help;