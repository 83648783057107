import React from "react";

const Cryptocurrency = () => {
    return (
        <>
            <div>
                <div className="investment-content">
                    <img className="investment-img" src="../../../assets/images/blog/investment.png" alt="" />
                </div>
                <div className="investment-data">
                    <div className="container">
                        <h5 className="investment-title">Cryptocurrency: Why is it a Good Investment?</h5>
                        <p className="investment-subtitle">In recent years, more and more prominent individuals advocate investing in
                            cryptocurrency because of its lucrative potential. However, what exactly makes it a good investment to begin
                            with?
                        </p>
                        <p className="investment-subtitle"> If you are interested in answering this question, then you have come to the
                            right page. In this article, we have compiled the top reasons why cryptocurrency is considered a good
                            investment.
                        </p>
                        <h5 className="investment-title ">Reasons Why Cryptocurrency is a Good Investment</h5>
                        <p className="investment-subtitle"> Here are the top reasons why cryptocurrencies can be considered as a good
                            investment:
                        </p>
                        <div className="row investment-lists">
                            <div className="col-md-4 mr-3">
                                <div className="invest-new">
                                    <img className=" investment-images " src="../../../assets/images/blog/Nuthawut-image.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <h5 className="investment-title mt-0">Stable and Censorship Resistant</h5>
                                <p className="investment-contents">Some may say that the decentralized characteristic of cryptocurrency
                                    makes it a dangerous place to invest in. However, on the flip side of this argument is that its
                                    decentralization makes it more stable and resistant to censorship.
                                    This means that it is virtually impossible for government agencies and political bodies to dilute
                                    its value using the power of inflation. </p>
                                <p className="investment-contents"> On top of that, it is also resistant to other disastrous scenarios such
                                    as bank failures. As a result, many are now considering cryptocurrency as digital gold.
                                </p>
                            </div>
                        </div>
                        <div className="row media investment-lists">
                            <div className="col-md-8">
                                <h5 className="investment-title mt-0">Transformational Technology</h5>
                                <p className="investment-contents"> Cryptocurrency incorporates the latest blockchain technology that can
                                    transform many industries such as healthcare, banking, supply chains, and healthcare. By simply
                                    reducing the number of intermediaries and providing services directly
                                    to consumers, both companies and clients can significantly save on cost.
                                </p>
                            </div>
                            <div className="col-md-4 ml-3">
                                <div className="invest-new">
                                    <img className=" investment-images " src="../../../assets/images/blog/invest-2.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row media investment-lists">
                            <div className="col-md-4 mr-3">
                                <div className="invest-new">
                                    <img className=" investment-images " src="../../../assets/images/blog/invest-3.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-8 media-body">
                                <h5 className="investment-title mt-0">Cryptocurrency is Considered to be More Profitable than Forex Trading
                                </h5>
                                <p className="investment-contents">Forex trading is a way of investment that has already been proven and
                                    tested by time. However, many investors are claiming that cryptocurrency investment is way more
                                    profitable because of several reasons.</p>
                                <p className="investment-contents">The first main reason is that forex trading has a steep learning curve
                                    that investors must master before they can truly succeed. However, you only need basic trading
                                    knowledge for cryptocurrency. </p>
                                <p className="investment-contents"> The second reason is that despite the infancy of cryptocurrency, it has
                                    a highly volatile market. This sees many price movements between the exchange floors and coin
                                    traded, enabling experienced traders to turn major profits.
                                </p>
                            </div>
                        </div>
                        <div className="row media investment-lists">
                            <div className="col-md-8 media-body">
                                <h5 className="investment-title mt-0">Final Words</h5>
                                <p className="investment-contents"> They say that cryptocurrency is the future of money and investment.
                                    There is some truth in this, but you must know that it is also highly volatile in nature. This is
                                    why before you decide on diving into cryptocurrency investment, make
                                    sure to do thorough research and study the existing markets properly so that you can make informed
                                    decisions.
                                </p>
                            </div>
                            <div className="col-md-4 ml-3">
                                <div className="invest-new">
                                    <img className=" investment-images " src="../../../assets/images/blog/invest-4.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Cryptocurrency;