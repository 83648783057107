import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const C2f = () => {
    const { t } = useTranslation();
    const location = useLocation();


    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, []);
    return (
        <>
            <div className="main-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="featured-allmain2">
                                <ul className="featured-total">
                                    <li>
                                        <img className="featured-line" src="../../../assets/images/Rectangle-64.svg" alt="a" />
                                    </li>
                                    <li>
                                        <h1 className="featured-title">C2F - COINOVY <br /> {t('LANDING.is a ERC -20 Token')}</h1>
                                    </li>
                                </ul>
                                <p className="usecoinovy-subcontent">
                                    {t('LANDING.Use Coinovy tokens confidently')}
                                </p>
                                <NavLink href="https://walletconnect.coinovy.com/connect" target="_blank"><button className="purchase-btn">Buy now</button></NavLink>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="tokens-image1">
                                <img src="../../../assets/images/c2ftoken/Group-24.svg" width={356} height={412} alt="Coinovy  C2F token" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="token-info">
                        <h1 className="token-info-txt">
                        {t('LANDING.Token Info')}
                        </h1>
                        <div className="token-info-section">
                            <h1 className="token-info-title">
                                Coinovy
                            </h1>
                            <div className="row">
                                <div className="col pr-0">
                                    <div className="token-details-box">
                                        <h6 className="token-type">
                                        {t('LANDING.Token Type')}
                                        </h6>
                                        <p className="token-name">
                                            BEP-20
                                        </p>
                                    </div>
                                </div>
                                <div className="col p-0">
                                    <div className="token-details-box token-details-box2">
                                        <h6 className="token-type">
                                        {t('LANDING.Ticker')}
                                        </h6>
                                        <p className="token-name">
                                            C2F
                                        </p>
                                    </div>
                                </div>
                                <div className="col p-0">
                                    <div className="token-details-box">
                                        <h6 className="token-type">
                                        {t('LANDING.Decimals')}
                                        </h6>
                                        <p className="token-name">
                                            18
                                        </p>
                                    </div>
                                </div>
                                <div className="col p-0">
                                    <div className="token-details-box token-details-box2">
                                        <h6 className="token-type">
                                        {t('LANDING.Current Price')}
                                        </h6>
                                        <p className="token-name token-name2">
                                            0.05 $
                                        </p>
                                    </div>
                                </div>
                                <div className="col pl-0">
                                    <div className="token-details-box token-details-box3">
                                        <h6 className="token-type">
                                            For BEP-20 details
                                        </h6>
                                        <p className="token-name token-name2">
                                            <NavLink style={{ textDecoration: 'none', color: '#f4cd06' }} href="https://bscscan.com/token/0x47a9ae81a024abbcb2166739b1846c5e7a0631ff?a=0x3010049750df88fb0b764a79a039d0d311186ca1" target="_blank">Click Here</NavLink>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Smart-Contract-section">
                    <h6 className="Smart-Contract-text">{t('LANDING.Smart Contract Address')}</h6>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="Smart-Contract-se">
                                    <form>
                                        <div className="input-group">
                                            <input type="text" onclick="window.open('https://bscscan.com/token/0x47a9ae81a024abbcb2166739b1846c5e7a0631ff?a=0x3010049750df88fb0b764a79a039d0d311186ca1')" id="myInput" className="form-control Smart-Contract-inp" defaultValue="0x47A9aE81A024aBbcb2166739b1846C5E7A0631ff" aria-describedby="button-addon2" readOnly />
                                            <button className="btn btn-outline-secondary Smart-btn" type="button" id="button-addon2"><i className="far fa-copy" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="token-allocation-sec">
                    <div className="container">
                        <h1 className="token-allocation-txt">
                        {t('LANDING.Token Allocation')}
                        </h1>
                        <img className="token-allocation-img" src="../../../assets/images/c2ftoken/Token-Allocation.png" width={622} height={392} alt="Coinovy  C2F token Allocation" loading="lazy" />
                        <h5 className="total-token">
                        {t('LANDING.Total tokens allocation')}
                        </h5>
                    </div>
                </div>
                <div className="roadmap-sec">
                    <div className="container">
                        <h1 className="roadmap-txt">
                        {t('LANDING.Roadmap')}
                        </h1>
                        <img className="roadmap-img" src="../../../assets/images/c2ftoken/roadmap-image.png" width={888} height={398} alt="Coinovy  C2F token Roadmap" loading="lazy" />
                        <p className="years-text">
                        {t('LANDING.Years after launch')}
                        </p>
                        <h5 className="breakdown-of">
                        {t('LANDING.Breakdown of the token')}
                        </h5>
                        <img className="roadmap-details-img" src="../../../assets/images/c2ftoken/roadmap-details.png" alt="Coinovy  C2F token RoadMap Parameters" loading="lazy" />
                    </div>
                </div>
                <div className="Why-Holding-section">
                    <div className="container">
                        <h1 className="Why-Holding-txt">
                            Why Holding C2F?
                        </h1>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="Why-Holding-sec">
                                    <h6 className="Why-txt">Never decreases in demand.</h6>
                                    <p className="Why-subtxt">The Coinovy coin is a crucial participant in our ecosystem. Since a token
                                        drives every crypto
                                        and fiat transaction, Coinovy needs to rescue its original tokens from the exchange market
                                        and destroy them every month to boost demand.</p>
                                </div>
                                <div className="Why-Holding-sec">
                                    <h6 className="Why-txt">Supply is limited</h6>
                                    <p className="Why-subtxt">You cannot produce or seize our coins. Therefore, the overall quantity of
                                        Coinovy tokens is continuously decreasing and has a beneficial effect on basic tokenomics.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Why-Holding-sec Why-Holding-sec2">
                                    <h6 className="Why-txt">Deposit C2F &amp; earn up to 21% annually</h6>
                                    <p className="Why-subtxt">Keep your C2F tokens on your saving account and earn C2F. A low-risk
                                        strategy that helps to boost your interest earnings.</p>
                                    <img className="Why-image" src="../../../assets/images/c2ftoken/Group-622.png" width={197} height={233} alt="Deposit Coinovy C2F and earn" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default C2f;