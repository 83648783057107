// import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import LCPImagePreloader from '../../LCPImagePreloader';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import './styles.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


const Home = () => {
    const [swiperRef, setSwiperRef] = useState(null);
    const { t } = useTranslation();
    const location = useLocation();

    const lcpImageSrc = '../../../assets/images/globe.webp';


    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, []);

    return (
        <>

            <Swiper
                loop={true}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="better-way-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="globe-text-section">
                                        <h1 className="betterway-title">{t('LANDING.Coinovy Made C2F')}
                                            <br />
                                            <span className="typewrite betterway-title2" data-period="2000" data-type='["Simple.", "Speed.", "Secure."]'>
                                                <span className="wrap">{t('LANDING.Simple /Speed/ Secure')}</span>
                                            </span>
                                        </h1>
                                        <p className="create-txt">{t('LANDING.Your search')}</p>
                                    </div>
                                    <div className="betterway-sub-txt">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="main-section2">
                                                    <img className="icon-1" src="../../../assets/images/currencies.svg" width={50} height={50} alt="fiat currencies" loading="eager" />
                                                    <h2 className="icon-head">{t('LANDING.19+ currencies')}</h2>
                                                </div>
                                            </div>
                                            {/* <div className="col-4">
                                                <div className="main-section2">
                                                    <img className="icon-1" width={50} height={50} src="../../../assets/images/Powered-by-Mastercard.png" alt="Coinovy powered by Mastercard" loading="eager" />
                                                    <h2 className="icon-head">{t('LANDING.Powered by Mastercard')}</h2>
                                                </div>
                                            </div> */}
                                            <div className="col-4">
                                                <div className="main-section2">
                                                    <picture>
                                                        <source srcSet="../../../assets/images/Apple_Pay_logo.webp" type="image/webp" />
                                                        <source srcSet="../../../assets/images/Apple_Pay_logo.webp" type="image/jpeg" />
                                                        <img className="icon-1 icon-2" src="../../../assets/images/Apple_Pay_logo.webp" alt="Coinovy powered by Apple pay" loading="eager" />
                                                    </picture>
                                                    <h2 className="icon-head">{t('LANDING.Apple Pay')}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="app-store-section">
                                            <li>
                                                <NavLink to="https://apps.apple.com/in/app/coinovy-bitcoin-crypto-wallet/id1590152152" target="_blank">
                                                    <picture>
                                                        <source srcSet="../../../assets/images/Apple-App-Store.webp" type="image/webp" />
                                                        <source srcSet="../../../assets/images/Apple-App-Store.webp" type="image/jpeg" />
                                                        <img className="app-store-iphone" width={130} height={50} src="../../../assets/images/Apple-App-Store.webp" alt="download coinovy on App store" loading="eager" />
                                                    </picture>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="https://play.google.com/store/apps/details?id=com.coinovy.coin" target="_blank"><img className="app-store-googleplay" src="../../../assets/images/Google-Play-store.svg" alt="download coinovy on Google play" loading="eager" /></NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="globe-sec">
                                        {/* <img className="globe-image" src="../../../assets/images/globe.webp" alt="Coinovy globe" loading="eager" /> */}
                                        <img className="globe-image" src={lcpImageSrc} alt="LCP Image" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="slidesection">
                                        <h2 className="slidehead1">{t('LANDING.COINOVY WALLET')}</h2>
                                        <h2 className="slidehead3">All-in-One Crypto Ecosystem</h2>
                                        <h1 className="slidehead2">COMING SOON</h1>
                                        <div className="btn-section">
                                            <NavLink to="https://apps.apple.com/in/app/coinovy-bitcoin-crypto-wallet/id1590152152" target="_blank"><img className="app-images" width={200} height={55} src="../../../assets/images/slide/Group-34282.png" alt="download coinovy on App store" /></NavLink>
                                            <NavLink to="https://play.google.com/store/apps/details?id=com.coinovy.coin" target="_blank"><img className="app-images app-images1" width={200} height={55} src="../../../assets/images/slide/Group-34283.png" alt="download coinovy on Google play" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <img className="slideright" width={540} height={421} src="../../../assets/images/slide/Group-34281.png" alt="Coinovy app on android and ios" />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

            {/*  */}
            <div className="the-only-wallet-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="theonlywallet-img-sec">
                                <img className="theonlywallet-image" src="../../../assets/images/picture-mobile.png" alt="Coinovy, Coinovy Global Master card, Crypto to Fiat" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="theonlywallet-text-section">
                                <ul className="theonlywallet">
                                    <li><img className="theonlywallet-line" src="../../../assets/images/Rectangle-64.svg" alt="" /></li>
                                    <li>
                                        <h1 className="theonlywallet-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                            {t('Home.CRYPTO TO FIAT – WITH')}
                                        </h1>
                                    </li>
                                </ul>
                                <p className="theonlywallet-subtitle">
                                    {t('LANDING.Have cryptocurrency')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  */}
            <div className="video-main-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="video-text">
                                <ul className="video-txt">
                                    <li><img className="video-txt-line" src="../../../assets/images/Rectangle-64.svg" alt="" /></li>
                                    <li>
                                        <h1 className="video-txt-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                            {t('LANDING.COINOVY - YOUR ALL')}
                                        </h1>
                                    </li>
                                </ul>
                                <p className="video-txt-subtitle">
                                    {t('LANDING.Coinovy is your one-stop')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="video-sec">
                                <video width="100%" poster="../../../assets/video/C-Video-IMage.png" controls>
                                    <source src="../../../assets/video/CoinovyTrailer-English.mp4" type="video/mp4" alt="Coinovy Trailer, Coinovy is a simplified Cryptocurrency platform that makes it easy to buy, sell, trade or convert crypto to fiat in less than 60 seconds securely." />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  */}
            {/* <div className="coinovy-features-section">
                <div className="container">
                    <ul className="coinovy-features-total">
                        <li><img className="coinovy-features-line" src="../../../assets/images/Rectangle-64.svg" alt="" /></li>
                        <li>
                            <h1 className="coinovy-features-title" >
                                COINOVY FEATURES
                            </h1>
                        </li>
                    </ul>
                    <div className="row justify-content-center">
                        <div className="col-md-7">
                            <div className="coinovy-features-left">
                                <img src="../../../assets/images/image12334.png" alt="Coinovy, Coinovy card, Coinovy Global Master card, Crypto to Fiat, Crypto wallet" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="coinovy-features-right">
                                <h2 className="coinovy-features1">
                                    All your digital finance needs in <span>one easy </span>to use <span>APP</span>
                                </h2>
                                <div className="line8" />
                                <p className="oinovy-features12">
                                    Everything a typical person could possibly need to make the most of the huge potential of digital financial
                                    assets all in one place with competitive rates and incredibly easy to use services
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/*  */}
            <div className="deposit-crpt-section">
                <div className="container">
                    <h1 className="deposit-crpt-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                        {t('LANDING.CROSS-BORDER')}
                    </h1>
                    <img className="deposit-crpt-line" src="../../../assets/images/lines.svg" alt="" />
                    <p className="deposit-crpt-subtext">
                        {t('LANDING.Need to')}
                    </p>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="laptop-img-section">
                                <img src="../../../assets/images/coinovyimage.svg" alt="Coinovy Bridge, Trading on both BSC and Ethereum" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  */}
            <div className="aipricing-content">
                <div className="container">
                    <div className="ai-inside-data">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="aipricing-right-image">
                                    <img className="aipricing-image" src="../../../assets/images/coinovy-image30.svg" alt="Coinovy Bridge provides you interoperability between two of the world’s leading blockchains  BSC and Ethereum" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="aipricing-allmain">
                                    <ul className="loan-total">
                                        <li><img className="aipricing-line" src="../../../assets/images/Rectangle-64.svg" alt="" /></li>
                                        <li>
                                            <h1 className="aipricing-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                                {t('LANDING.INTER-BLOCK')} <br></br>
                                                COINOVY BRIDGE</h1>
                                        </li>
                                    </ul>
                                    <p className="aipricing-subcontent">
                                        {t('LANDING.Not sure')}

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  */}
            {/* <div className="realtime-notifications">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="realtime-allmain">
                                <ul className="realtime-total">
                                    <li><img className="realtime-line" src="../../../assets/images/Rectangle-64.svg" alt="" /></li>
                                    <li>
                                        <h1 className="realtime-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                            {t('LANDING.COINOVY GLOBAL')}  <br /> MASTERCARD DEBIT</h1>
                                    </li>
                                </ul>
                                <p className="realtime-subcontent">
                                    {t('LANDING.Coinovy brings')}
                                </p>
                                <img className="line-image" src="/../../../assets/images/lines.svg" alt="" />
                                <div className="row">
                                    <div className="col-12">
                                        <ul className="realtime-transaction">
                                            <li><img className="doller-img" src="/../../../assets/images/doller.svg" alt="Coinovy global Master debit card accept over 42 million Merchants" />
                                                <h2 className="transaction-title">
                                                    {t('LANDING.Accept over')}<br /> {t('LANDING.42 M + Merchants.')}
                                                </h2>
                                            </li>
                                            <li>
                                                <picture>
                                                    <source srcSet="../../../assets/images/atm.webp" type="image/webp" />
                                                    <source srcSet="../../../assets/images/atm.webp" type="image/jpeg" />
                                                    <img className="doller-img" src="../../../assets/images/atm.webp" alt="with Coinovy global VISA debit card  withdraw crypto at ATM's" />
                                                </picture>
                                                <h2 className="transaction-title">
                                                    {t('LANDING.ATM withdrawals')}
                                                </h2>
                                            </li>
                                            <li>
                                                <picture>
                                                    <source srcSet="../../../assets/images/Apple_Pay_logo.webp" type="image/webp" />
                                                    <source srcSet="../../../assets/images/Apple_Pay_logo.webp" type="image/jpeg" />
                                                    <img className="doller-img doller-img-1" src="../../../assets/images/Apple_Pay_logo.webp" alt="Coinovy powered by Apple pay" loading="eager" />
                                                </picture>
                                                <h2 className="transaction-title">
                                                    {t('LANDING.Pay with Apple Pay')}
                                                </h2>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="realtime-right-image">
                                <picture>
                                    <source srcSet="../../../assets/images/cards-image.webp" type="image/webp" />
                                    <source srcSet="../../../assets/images/cards-image.webp" type="image/jpeg" />
                                    <img className="realtime-image" src="../../../assets/images/cards-image.webp" alt="Coinovy global Master debit card" />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/*  */}
            <div className="Coinovy-Instant-Loans">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="Instant-Loan-image">
                                <img src="../../../assets/images/coinovy-instant-loans.svg" alt="Coinovy instant loans for crypto holdings" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="Coinovy-InstantLoans-text">
                                <ul className="Coinovy-InstantLoans-total">
                                    <li><img className="Coinovy-InstantLoans-line" src="../../../assets/images/Rectangle-64.svg" alt="" />
                                    </li>
                                    <li>
                                        <h1 className="Coinovy-InstantLoans-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                            COINOVY <br /> {t('LANDING.INSTANT LOANS')}</h1>
                                    </li>
                                </ul>
                                <p className="Coinovy-InstantLoans-subcontent" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1500} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                    {t('LANDING.Coinovy is your')}
                                    Coinovy is your partner in ventures. Get up to $5000 loan against your crypto holdings and finance your other ventures. No lengthy application processes, no delays. With Coinovy, you get your ventures financed in less than 5 minutes!
                                </p>
                                <img className="line-image" src="/../../../assets/images/lines.svg" alt="" />
                                <div className="row">
                                    <div className="col-12">
                                        <ul className="Coinovy-InstantLoans-transaction">
                                            <li><img className="doller-img1" src="../../../assets/images/atm.svg" width={50} height={50} alt="mini icon" />
                                                <h2 className="transaction-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                                    {t('LANDING.Flexible Instalments')}
                                                </h2>
                                            </li>
                                            <li><img className="doller-img" src="/../../../assets/images/secure.svg" width={50} height={50} alt="mini icon" />
                                                <h2 className="transaction-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                                    {t('LANDING.Instant disbursal')}
                                                </h2>
                                            </li>
                                            <li><img className="doller-img" width={50} height={50} src="/../../../assets/images/doller.svg" alt="mini icon" />
                                                <h2 className="transaction-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                                    {t('LANDING.1% interest')}
                                                </h2>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  */}
            <div className="coinovy-wallet-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="image-section">
                                <img src="../../../assets/images/coinovy-wallet-img.svg" alt="Coinovy crypto wallet" />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="coinovy-wallet-text">
                                <ul className="coinovy-wallet-total">
                                    <li><img className="coinovy-wallet-line" src="../../../assets/images/Rectangle-64.svg" alt="no-image" /></li>
                                    <li>
                                        <h1 className="coinovy-wallet-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                            {t('LANDING.COINOVY WALLET')}
                                        </h1>
                                    </li>
                                </ul>
                                <p className="coinovy-wallet-subcontent" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1500} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                    {t('LANDING.Coinovy gives')}

                                </p>
                                <img className="coinovy-wallet-line" src="../../../assets/images/lines.svg" alt="no" />
                                <ul className="img-text-section">
                                    <li>
                                        <h2 className="img-text-title"><span><img className="img-text-img" src="../../../assets/images/Secure2.svg" alt="Coinovy simple crypto platform for beginners" /></span>{t('LANDING.SIMPLE')}</h2>
                                    </li>
                                    <li>
                                        <h2 className="img-text-title"><span><img className="img-text-img ml-4" src="../../../assets/images/Speed.svg" alt="Coinovy speed, with Coinovy convert crypto to fiat in less than 60 seconds securely" /></span>{t('LANDING.FAST')}</h2>
                                    </li>
                                    <li>
                                        <h2 className="img-text-title"><span><img className="img-text-img ml-4" src="../../../assets/images/Simple.svg" alt="Coinovy most secure crypto platform" /></span>{t('LANDING.SECURE')}</h2>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  */}
            <div className="security-section">
                <div className="container">
                    <h1 className="security-head">SECURITY</h1>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="security-se">
                                <img className="imagesnew" src="../../../assets/images/Group-138.png" alt="Coinovy was certified crypto platform" />
                                <h2 className="Certified-head">Certified</h2>
                                <p className="Certified-subhead">Coinovy is certified, giving your funds the highest level of protection possible.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="security-se">
                                <img className="imagesnew" src="../../../assets/images/Group-137.png" alt="Coinovy was licensed e-money issuer" />
                                <h2 className="Certified-head">Licensed</h2>
                                <p className="Certified-subhead">Coinovy is a licensed e-money issuer</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="security-se">
                                <img className="imagesnew" src="../../../assets/images/Group-136.png" alt="Coinovy Multi-signature, cold-storage cryptocurrency accounts protect your currency" />
                                <h2 className="Certified-head">Protected</h2>
                                <p className="Certified-subhead">Multi-signature, cold-storage cryptocurrency accounts protect your currency</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="security-se">
                                <img className="imagesnew" src="../../../assets/images/Group-139.png" alt="Coinovy was most secure crypto platform with has 2-Factor Authentication feature" />
                                <h2 className="Certified-head">Secure</h2>
                                <p className="Certified-subhead">2-Factor Authentication (2FA) and sophisticated Device Authorisation ensures that
                                    only you can access your account
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="partners">
                <h1 className="security-head">{t('LANDING.Coinovy Partnerships')}</h1>
                <div className="container">
                    <div className="row">
                        <Swiper
                            loop={true}
                            direction={"horizontal"}
                            onSwiper={setSwiperRef}
                            centeredSlides={false}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{

                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 40,
                                },
                            }}
                            navigation={true}
                            pagination={{
                                type: 'custom',
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                            grabCursor={true}
                            freeMode={true}
                            spaceBetween={40}
                            scrollbar={{ draggable: true }}
                            dir="ltr"
                        >
                            <SwiperSlide>
                                <img className="partner-image" src="../../../assets/images/partnerships/chakravuyha.webp" alt="Coinovy featured in The Crypto Daily News media" />
                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/partnerships/Group3.webp" alt="Coinovy is investment partner with PRUDENT INVESTMENT MANAGEMENT LLC" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/partnerships/Group4.webp"
                                    alt="Coinovy is EU operations partner with BlockchainArmy" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/partnerships/Group5.png" alt="Coinovy was PR partner with LUNA PR" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/partnerships/Group6.png"
                                    alt="Coinovy is stratagic partner with Coinranking" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/partnerships/Group7.png"
                                    alt="Coinovy is techology partner with Crypterium" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/partnerships/Group8.png"
                                    alt="Coinovy is funtional partner with LaunchMyICO" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/partnerships/Group9.webp"
                                    alt="Coinovy is stratagic partner with United Africa Blockchain Association" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/partnerships/Group-31.webp"
                                    alt="Coinovy is stratagic partner with Blubitex" />

                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>


            <section className="partners">
                <h1 className="security-head">{t('LANDING.Featured in media')}</h1>
                <div className="container">
                    <div className="row">
                        {/* <Swiper
                            loop={true}
                            direction={"horizontal"}
                            onSwiper={setSwiperRef}
                            centeredSlides={false}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                410: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 40,
                                },
                            }}
                            navigation={true}
                            pagination={{
                                type: 'custom',
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                            grabCursor={true}
                            freeMode={true}
                            spaceBetween={40}
                            scrollbar={{ draggable: true }}
                            dir="ltr"
                        >
                            <SwiperSlide>
                                <img className="partner-image" src="../../../assets/images/media/Group-16.png" alt="Coinovy featured in The Crypto Daily News media" />
                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-17.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-18.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-19.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-20.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-21.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-22.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-23.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-24.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-25.png" alt="Coinovy featured in Hindustan Times media" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-26.png" alt="Coinovy featured in Digital Journal media" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-27.png" alt="Coinovy featured in MarketWatch media" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-28.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-29.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-30.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-44.png" alt="Coinovy featured in CoinMarketCap media" />

                            </SwiperSlide>
                        </Swiper> */}

                        <Swiper navigation={true} modules={[Navigation]}
                            loop={true}
                            breakpoints={{
                                370: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                640: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 50,
                                },
                            }}
                            className="mySwiper">
                            <SwiperSlide>
                                <img className="partner-image" src="../../../assets/images/media/Group-16.png" alt="Coinovy featured in The Crypto Daily News media" />
                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-17.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-18.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-19.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-20.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-21.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-22.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-23.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-24.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-25.png" alt="Coinovy featured in Hindustan Times media" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-26.png" alt="Coinovy featured in Digital Journal media" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-27.png" alt="Coinovy featured in MarketWatch media" />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-28.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-29.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-30.png" alt="..." />

                            </SwiperSlide>
                            <SwiperSlide>

                                <img className="partner-image" src="../../../assets/images/media/Group-44.png" alt="Coinovy featured in CoinMarketCap media" />

                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Home;