import React from "react";
import { useTranslation } from 'react-i18next';

const Wallets = () => {
    const { t } = useTranslation();
    return (
        <>
            <div>
                {/* wallet-section */}
                <div className="wallet-card-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="wallet-text-section">
                                    <h1 className="the-most-convenient">{t('WALLET.The most')} <br />
                                        <span>{t('WALLET.convenient')} </span> way<br /> {t('WALLET.to buy cryptos')}</h1>
                                    <p className="Buying-cryptos">
                                        {t('WALLET.Buying cryptos')}
                                    </p>

                                    <div>
                                        <img className="buy-crypto-line" src="../../../assets/images/wallet/lines.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="wallet-cards-image">
                                    <img src="../../../assets/images/wallet/wallet-cards.svg" width={540} height={497} alt="Coinovy  Crypto Wallet" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* make-your-dreams-section */}
                <div className="make-your-dreams-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="makeyour-dreams-img">
                                    <img src="../../../assets/images/wallet/coins-crypto.svg" width={322} height={303} alt="" data-aos="fade-up" data-aos-offset={200} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="makeyour-text-section">
                                    <ul className="makeyour-text">
                                        <li><img className="makeyour-line" src="../../../assets/images/Rectangle-64.svg" alt="" />
                                        </li>
                                        <li>
                                            <h2 className="makeyour-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('WALLET.MAKE YOUR')}<br />{t('WALLET.COME TRUE WITH')}<span>{t('WALLET.CRYPTO')}</span></h2>
                                        </li>
                                    </ul>
                                    <p className="makeyour-content">
                                        {t('WALLET.Buy your')}
                                    </p>
                                    <div>
                                        <img className="makeyour-line" src="../../../assets/images/wallet/lines.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* buy-with-crypto-section */}
                <div className="buy-with-crypto-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="buywithcrypto-guide-text">
                                    <ul className="crypto-guide-text">
                                        <li><img className="crypto-guide-line" src="../../../assets/images/Rectangle-64.svg" alt="" />
                                        </li>
                                        <li>
                                            <h2 className="crypto-guide-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('WALLET.BUY WITH')}<br />{t('WALLET.CRYPTO')} <span>{t('WALLET.GUIDE')}</span></h2>
                                        </li>
                                    </ul>
                                    <p className="crypto-guide-content">
                                        {t('WALLET.Its always')}
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="crypto-guide-image">
                                    <img src="../../../assets/images/wallet/crypto-guide-image.svg" width={571} height={477} alt="Coinovy  Crypto Guides" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Several-exchanges-section */}
                <div className="Several-exchanges-section">
                    <div className="container">
                        <h2 className="Several-exchanges-text" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                            {t('WALLET.SEVERAL')} <span>{t('WALLET.EXCHANGES')} </span>{t('WALLET.IN ONE PLACE')}</h2>
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <p className="Several-exchanges-subtext">
                                    {t('WALLET.Tired of')}
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="checkandcompare">
                                            <img className="checkandcompare-img" src="../../../assets/images/wallet/check-and-compare-image.svg" alt="" />
                                            <h2 className="checkandcompare-text" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('WALLET.CHECK AND')} <br />
                                                {t('WALLET.COMPARE PRICES')}</h2>
                                            <p className="checkandcompare-subtext">
                                                {t('WALLET.AI-powered')}
                                                <br /> {t('WALLET.monitor the exchange rates of all')}<br />
                                                {t('WALLET.exchange platforms to help you choose')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="checkandcompare">
                                            <img className="checkandcompare-img" src="../../../assets/images/wallet/Exchange-crypto.svg" alt="" />
                                            <h2 className="checkandcompare-text" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('WALLET.EXCHANGE')}EXCHANGE <br />
                                                {t('WALLET.CRYPTO GUIDE')}</h2>
                                            <p className="checkandcompare-subtext">
                                                {t('WALLET.Its always best')} {t('WALLET.basics. Watch or read our crypto')} {t('WALLET.guide to exchange with ease.')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Store your money without fear */}
                <div className="Store-your-money-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="Store-your-money-image">
                                    <img src="../../../assets/images/wallet/Store-your-money.png" width={497} height={397} alt="Store your digital currencies  in Coinovy" data-aos="zoom-out-right" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Storeyourmoney-text">
                                    <ul className="Storeyourmoney-text1">
                                        <li><img className="Storeyourmoney-line" src="../../../assets/images/Rectangle-64.svg" alt="" /></li>
                                        <li>
                                            <h2 className="Storeyourmoney-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false"><span> {t('WALLET.STORE')}
                                            </span>{t('WALLET.YOUR MONEY')}<br /> {t('WALLET.WITHOUT FEAR')}
                                            </h2>
                                        </li>
                                    </ul>
                                    <p className="Storeyourmoney-content">
                                        {t('WALLET.Store your')}

                                    </p>
                                    {/* <a href=""><button class="Storeyourmoney-btn">{{'WALLET.Create your wallet' | translate}}</button></a> */}
                                    <div>
                                        <img className="Storeyourmoney-line" src="../../../assets/images/wallet/lines.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Multicurrency digital wallet  */}
                <div className="Multicurrency-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="Multicurrency-text-section">
                                    <ul className="Multicurrency-text">
                                        <li><img className="Multicurrency-line" src="../../../assets/images/Rectangle-64.svg" alt="" /></li>
                                        <li>
                                            <h2 className="Multicurrency-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false"><span>{t('WALLET.MULTICURRENCY')}</span> <br />
                                                {t('WALLET.DIGITAL WALLET')}</h2>
                                        </li>
                                    </ul>
                                    <p className="Multicurrency-content">
                                        {t('WALLET.No need')}
                                    </p>
                                    <div>
                                        <img className="Multicurrency-line" src="../../../assets/images/wallet/lines.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Multicurrency-image2">
                                    <img src="../../../assets/images/wallet/Multicurrency-image.png" width={540} height={432} alt="Coinovy Multi Currency Digital  Wallet" data-aos="zoom-out-left" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Withdraw your money anytime, anywhere */}
                <div className="Withdraw-your-money">
                    <div className="container">
                        <h2 className="Withdraw-your-text" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                            <span>{t('WALLET.WITHDRAW')}</span>{t('WALLET.YOUR')}
                        </h2>
                        <p className="stop-waiting">
                            {t('WALLET.Stop waiting')}
                        </p>
                        <img className="line-img2" src="../../../assets/images/wallet/lines.svg" alt="no-img" />
                        <div className="row">
                            <div className="col-md-4">
                                <div>
                                    <div className="media mb-4 sidesec">
                                        <img className="mr-3 media-image" src="../../../assets/images/wallet/cashout-worldwide.svg" alt="no" />
                                        <div className="media-body">
                                            <h5 className="media-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('WALLET.CASHOUT WORLDWIDE')}</h5>
                                            <p className="media-subtitle">
                                                {t('WALLET.Get hold')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <div className="media mb-4 sidesec">
                                        <img className="mr-3 media-image" src="../../../assets/images/wallet/cashout-guide.svg" alt="no" />
                                        <div className="media-body">
                                            <h5 className="media-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('WALLET.CASHOUT GUIDE')}</h5>
                                            <p className="media-subtitle">
                                                {t('WALLET.Check out')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <div className="media mb-4 sidesec">
                                        <img className="mr-3 media-image" src="../../../assets/images/wallet/crypto-guide.svg" alt="no" />
                                        <div className="media-body">
                                            <h5 className="media-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('WALLET.CRYPTO GUIDE')}</h5>
                                            <p className="media-subtitle">
                                                {t('WALLET.Its always best to')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img className="line-img3" src="../../../assets/images/wallet/lines.svg" alt="no-img" />
                    </div>
                </div>
                {/* Use your crypto as much as you want */}
                <div className="use-your-crypto-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="use-your-crypto-image">
                                    <img src="../../../../assets/images/wallet/Use-your-crypto-image.png" width={529} height={620} alt="Coinovy Multi Currency Digital  Wallet,  cashout crypto" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="useyour-crypto-text-section">
                                    <ul className="useyour-crypto-text">
                                        <li><img className="useyour-crypto-line" src="../../../assets/images/Rectangle-64.svg" alt="" /></li>
                                        <li>
                                            <h2 className="useyour-crypto-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('WALLET.USE YOUR')} {t('WALLET.CRYPTO')}  <br />
                                            {t('WALLET.MUCH AS YOU WANT')}</h2>
                                        </li>
                                    </ul>
                                    <p className="useyour-crypto-content">
                                    {t('WALLET.Pay with')}
                                        
                                    </p>
                                    <img className="useyour-crypto-line2" src="../../../assets/images/wallet/lines.svg" alt="" />
                                    <div className="useyour-crypto-media">
                                        <div className="media">
                                            <img className="mr-3 creditcard-image" src="../../../assets/images/wallet/credit-card-black.svg" alt="" />
                                            <div className="media-body">
                                                <p className="useyour-coinovy">
                                                {t('WALLET.Use your')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* send crypto to anyone */}
                <div className="sendcrypto-toanyone">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="sendcrypto-text-section">
                                    <ul className="sendcrypto-text">
                                        <li><img className="sendcrypto-line" src="../../../assets/images/Rectangle-64.svg" alt="" />
                                        </li>
                                        <li>
                                            <h2 className="sendcrypto-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('WALLET.SEND CRYPTO')} <br />{t('WALLET.AROUND THE WORLD')}</h2>
                                        </li>
                                    </ul>
                                    <p className="sendcrypto-content">
                                    {t('WALLET.Need to send')}
                                    </p>

                                    <div>
                                        <img className="sendcrypto-line1" src="../../../assets/images/wallet/lines.svg" alt="" />
                                    </div>
                                </div>
                                <div className="sendcrypto-sec2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div>
                                                <img className="easy-transfer-img" src="../../../assets/images/wallet/transfer-process.svg" alt="" />
                                                <h2 className="easy-transfer" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false"> {t('WALLET.Easy transfer process')}</h2>
                                                <p className="easy-transfer-sub">
                                                {t('WALLET.Need to send')}
                                                    
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div>
                                                <img className="easy-transfer-img" src="../../../assets/images/wallet/massive-savings.svg" alt="" />
                                                <h2 className="easy-transfer" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false"> {t('WALLET.Massive savings')}</h2>
                                                <p className="easy-transfer-sub">
                                                {t('WALLET.Input the')}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div>
                                                <img className="easy-transfer-img" src="../../../assets/images/wallet/information.svg" alt="" />
                                                <h2 className="easy-transfer" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('WALLET.Sending crypto guide')}</h2>
                                                <p className="easy-transfer-sub">
                                                {t('WALLET.Its always best to start')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="sendcrypto-img4">
                                    <img src="../../../assets/images/wallet/group-1360.svg" width={445} height={386} alt="With Coinovy  Send Crypto to anyone around the world" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Wallets;