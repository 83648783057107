import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const Header = () => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

    // Function to handle language change
    const handleLanguageChange = () => {
        // Toggle the language between 'en' and 'ta'
        const newLanguage = language === 'en' ? 'ch' : 'en';
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage); // Change the language using i18n
        localStorage.setItem('language', newLanguage);
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "./assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <header className="container">
                <nav className="navbar navbar-expand-lg">
                    <ul className="logo-section">
                        <li>
                            <NavLink className="navbar-brand" to="/"><img src="../../../../assets/images/coinovy-logo.png" className="main-logo" alt="Coinovy logo" loading="eager" /></NavLink>
                        </li>
                    </ul>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#dlt" aria-controls="dlt" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fas fa-bars" style={{ color: '#F4CD06' }} />
                    </button>
                    <div className="collapse navbar-collapse" id="dlt">
                        <ul className="navbar-nav mx-auto custom-header">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/c2f">C2F</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <div className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('LANDING.Company')}
                                </div>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <NavLink className="dropdown-item drop-down-txt" to="/about-coinovy">{t('LANDING.About Us')}</NavLink>
                                    {/* <NavLink className="dropdown-item drop-down-txt" to="../../../../webframe/about.html" target="_blank">About Coinovy</NavLink> */}
                                    <NavLink className="dropdown-item drop-down-txt" to="/coinovy-blog">{t('header.CoinovyBlog')}</NavLink>
                                    <NavLink className="dropdown-item drop-down-txt" to="/coinovy-media">{t('LANDING.Media')}</NavLink>
                                </div>
                            </li>
                            
                            <li className="nav-item">
                                <NavLink className="nav-link" to="./Coinovy_WP-2.0.pdf.pdf" target="_blank">{t('LANDING.White Paper')}</NavLink>
                            </li>
                        </ul>
                        <div className="form-inline my-2 my-lg-0">
                            <ul className="login-signup-sec">
                                {/* <li><NavLink className="login-txt" to="https://walletconnect.coinovy.com/connect" target="_blank">{t('LANDING.Wallet Connect')}</NavLink>
                                </li> */}
                                {/* <li>
                                    <NavLink className="coinovypay" to="https://coinovy.io" target="_blank">Coinovy Pay</NavLink>
                                </li> */}
                                <li>
                                    <NavLink className="coinovypay" to="https://walletconnect.coinovy.com/connect" target="_blank">Wallet Connect</NavLink>
                                </li>
                                <li>
                                    {/* <div className="dropdown">
                                        <button className="btn dropbutton-inside dropdown-toggle" type="button" id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-globe-asia" /> EN
                                        </button>
                                        <button className="btn dropbutton-inside dropdown-toggle" type="button" id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-globe-asia" /> 中文
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                            <NavLink className="dropdown-item drop-down-txt" >English</NavLink>
                                            <NavLink className="dropdown-item drop-down-txt">中文</NavLink>
                                        </div>
                                    </div> */}
                                    <form className="form-inline d-flex" style={{ alignItems: 'center' }}>
                                        <div className="bg-lan-clr">
                                            <div className="form-check form-switch" >
                                                <input
                                                    className="form-check-input lang-switch"
                                                    type="checkbox"
                                                    id="changeLanguage"
                                                    name="changeLanguage"
                                                    onChange={handleLanguageChange}
                                                    checked={language === 'ch'}
                                                    aria-label="changeLanguage"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </li>
                            </ul>
                        </div >
                    </div >
                </nav >
            </header >
        </>
    )
}
export default Header;