import React from "react";

const CryptoNews = () => {
    return (
        <>
            <div>
                <div className="cryptonews-content">
                    <img className="cryptonews-img" src="../../../assets/images/blog/cryptonews-bg.png" alt="" />
                </div>
                <div className="cryptonews-data">
                    <div className="container">
                        <h3 className="cryptonews-title">Crypto News: Is Bitcoin’s Price Rising?</h3>
                        <p className="cryptonews-subtitle"> Since its initial launch in 2009, Bitcoin has rapidly gained popularity and
                            became one of the most prominent cryptocurrencies worldwide. In fact, Bitcoin enjoys the top spot on the
                            list of the most lucrative cryptocurrency investments, with
                            millions of users investing in it globally.<br /> However, with the crypto industry’s highly volatile market,
                            it can be easy to suffer from losses, with Bitcoin’s price sometimes dipping significantly within a short
                            period. Still, what
                            about recently? Is Bitcoin’s price rising?
                        </p>
                        <h3 className="cryptonews-title">Is Bitcoin’s Price Increasing?</h3>
                        <p className="cryptonews-subtitle">Fortunately, yes, Bitcoin’s price has risen significantly in recent days, with
                            one BTC being valued at around 40,000 USD.</p>
                        <p className="cryptonews-subtitle">This is seen as a positive change from the currency’s recent downward trend
                            wherein its value fell below 30,000 USD in July 2021. This comes after Bitcoin experienced having the
                            highest value ever, with one BTC valued at over 60,000 USD.</p>
                        <p className="cryptonews-subtitle"> Of course, given the market’s high level of volatility, this increase doesn’t
                            guarantee it’ll remain that way in the future or a long-term reversal.
                        </p>
                        <h3 className="cryptonews-title"> Why Is Bitcoin’s Price Rising?</h3>
                        <p className="cryptonews-subtitle">A potential reason behind the cryptocurrency’s recent increase is the rumor that
                            some large companies, like Amazon, have taken a recent interest in digital currencies.
                        </p>
                        <p className="cryptonews-subtitle">This was further fueled by the online retailer’s latest job advertisement, which
                            was looking for someone to fill in the role of developing their Blockchain strategy and digital currency
                            product roadmap.</p>
                        <p className="cryptonews-subtitle">Besides Amazon, the recent price increase is also thought to result from leading
                            names in the tech industry expressing support for Bitcoin. These include figures like Jack Dorsey, the CEO
                            of the popular platform Twitter.
                        </p>
                        <h3 className="cryptonews-title">What Investors Need to Remember</h3>
                        <p className="cryptonews-subtitle"> As mentioned, the cryptocurrency market is a highly volatile one, and Bitcoin is
                            no exception. Because of this, despite the recent price swings, investors can expect this volatility to
                            continue. In fact, whether they’re novices or veterans,
                            experts recommend that investors keep their cryptocurrency investments to only less than 5% of their
                            portfolio. </p>
                        <p className="cryptonews-subtitle">Moreover, similar to how a sudden decrease shouldn’t influence your investment
                            decisions, it’s best to avoid modifying your long-term strategies due to a sudden rise in prices. Of course,
                            this also means avoiding purchasing more Bitcoin or
                            other cryptocurrencies just because of the recent increase in prices. </p>
                        <p className="cryptonews-subtitle"> Overall, Bitcoin can be an excellent investment. Still, the market for crypto
                            investments tends to be volatile, with prices changing rapidly in short periods.<br /> With that said, while
                            Bitcoin’s price is currently on an upward trend, it’s
                            best not to forget that this can immediately change in a moment. Therefore, you need to approach crypto
                            investments strategically.
                        </p>
                    </div>
                </div>
            </div>

        </>
    )
}
export default CryptoNews;