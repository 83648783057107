import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <div className="footer-content">
                <div className="container">
                    <ul className="footer-top">
                        <li>
                            <NavLink to="/home">
                                <img className="footer-imgdata" src="../../../../assets/images/footer-logo.svg" alt="Coinovy logo" loading="lazy" />
                            </NavLink>
                        </li>
                        <li className="ml-auto">
                            <ul className="add-download">
                                <li className="download-title">Download</li>
                                <li className="download-title">
                                    <NavLink className="app-store-img" to="https://apps.apple.com/in/app/coinovy-bitcoin-crypto-wallet/id1590152152" target="_blank" aria-label="Visit our Mobile App in  Apple Store"><i className="fab fa-apple" /></NavLink>
                                </li>
                                <li className="download-title">
                                    <NavLink className="app-store-img" to="https://play.google.com/store/apps/details?id=com.coinovy.coin" target="_blank" aria-label="Visit our Mobile App in  GooglePlay Store"><i className="fab fa-google-play" /></NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div className="row">
                        <div className="col-md-3">
                            <ul className="footer-data">
                                <li className="footer-data-title">COMPANY</li>
                                <li>
                                    {/* <NavLink to="/about-coinovy" style={{ textDecoration: 'none' }}>
                                        <p className="footer-data-subtitle footer-data-subtitle2" >About Coinovy</p>
                                    </NavLink> */}
                                    <NavLink to="../../../../webframe/about.html" target="_blank" style={{ textDecoration: 'none' }}>
                                        <p className="footer-data-subtitle footer-data-subtitle2" >About Coinovy</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/Coinovy-media" style={{ textDecoration: 'none' }}>
                                        <p className="footer-data-subtitle footer-data-subtitle2" >Coinovy Media</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/Coinovy-blog" style={{ textDecoration: 'none' }}>
                                        <p className="footer-data-subtitle footer-data-subtitle2" >Coinovy Blog</p>
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="https://coinovymembers.com/" target="_blank" style={{ textDecoration: 'none' }}> <p className="footer-data-subtitle footer-data-subtitle2 footer-data-subtitle3">Get Card
                                    </p></NavLink>
                                </li> */}
                                <li>
                                    <NavLink to="/help" style={{ textDecoration: 'none' }}>
                                        <p className="footer-data-subtitle footer-data-subtitle2" >Help</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/careers" style={{ textDecoration: 'none' }}>
                                        <p className="footer-data-subtitle footer-data-subtitle2">Careers <span className="weare-cls">We're
                                            Hiring</span></p>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <ul className="footer-data">
                                <li className="footer-data-title">PRODUCTS</li>
                                <li>
                                    <NavLink to="/wallets" style={{ textDecoration: 'none' }}>
                                        <p className="footer-data-subtitle">Crypto Wallet</p>
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/cards" style={{ textDecoration: 'none' }}>
                                        <p className="footer-data-subtitle">Coinovy Mastercard</p>
                                    </NavLink>
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <ul className="footer-data">
                                <li className="footer-data-title">CONDITIONS</li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="./Terms of service.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Terms and Conditions</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="../../../../pdf/coinovy-anti-fraud-policy.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Anti Fraud Policy</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="../../../../pdf/coinovy-cookie-policy.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Cookie Policy</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="./terms_of_credits.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Terms of Credits</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="../../../../webframe/coinovy-privacy-policy.html" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Privacy Policy</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="../../../../pdf/coinovy-privacy-policy.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Coinovy Privacy Policy</p>
                                    </NavLink>
                                </li>
                                <li>
                                    {/* <NavLink style={{ textDecoration: 'none' }} to="./Coinovy_terms_of_use.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Terms Of Use</p>
                                    </NavLink> */}
                                    <NavLink style={{ textDecoration: 'none' }} to="../../../../webframe/coinovy-terms-of-use.html" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Terms Of Use</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="./Coinovy_terms_of_exchange_operations.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Terms Of Exchange</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="../../../../pdf/coinovy-terms-of-exchange-operations.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Terms Of Exchange Operations</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="../../../../pdf/coinovy-ip-notice.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Coinovy Ip Notice</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="../../../../pdf/coinovy-walletto-terms.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Walletto Terms</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="../../../../pdf/coinovy-walletto-annex-prohibited-activity.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Walletto Annex Prohibited Activity</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="../../../../pdf/coinovy-kyc-aml-policy.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Coinovy kyc Aml Policy</p>
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink style={{ textDecoration: 'none' }} to="../../../../pdf/terms-and-conditions-of-payment-cards.pdf" target="_blank">
                                        <p className="footer-data-subtitle footer-data-subtitle2">Terms and Conditions of Payment Cards</p>
                                    </NavLink>
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul className="footer-data">
                                <li className="footer-data-title">CONTACT US</li>
                                <li className="footer-data-subtitle">
                                    <ul className="footer-social-icons">
                                        <li>
                                            <NavLink className="social-icons" to="https://www.facebook.com/Coinovy-107120688292344" target="_blank" aria-label="Visit our Facebook page"><i className="fab fa-facebook-f" /></NavLink>
                                            <NavLink className="social-icons" to="https://twitter.com/CoinovyOfficial" target="_blank" aria-label="Visit our twitter page"><i className="fab fa-twitter" /></NavLink>
                                            <NavLink className="social-icons" to="https://www.youtube.com/c/Coinovy" target="_blank" aria-label="Visit our Youtube page"><i className="fab fa-youtube" /></NavLink>
                                            <NavLink className="social-icons" to="https://t.me/coinovy" target="_blank" aria-label="Visit our Telegram page"><i className="fab fa-telegram-plane" /></NavLink>
                                            <NavLink className="social-icons" to="https://www.instagram.com/CoinovyOfficial/" target="_blank" aria-label="Visit our Instagram page"><i className="fab fa-instagram" /></NavLink>
                                            <NavLink className="social-icons" to="https://www.linkedin.com/company/coinovy" target="_blank" aria-label="Visit our Linked page"><i className="fab fa-linkedin" /></NavLink>
                                            <NavLink className="social-icons" to="https://medium.com/@coinovy.official" target="_blank" aria-label="Visit our Medium page"><i className="fab fa-medium" /></NavLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <div className="footer-last">COINOVY OU (License No -1700653.01) 1501, HDS Towers, Cluster F, JLT, Dubai, UAE.</div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-last">2017-2022 Coinovy.com</div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Footer;