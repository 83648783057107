import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

const AboutCoinovy = () => {
    const { t } = useTranslation();
    const location = useLocation();


    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, []);
    return (
        <>
            <div>
                {/* about-us */}
                <div className="aboutus-section">
                    <div className="container">
                        <div className="aboutus-image">
                            <img src="../../../assets/images/aboutus/aboutus-image.png" width={999} height={390} alt="About Coinovy" />
                        </div>
                        <div className="ourstory-text-section">
                            {/* <p className="ourstory-text">
                                {t('LANDING.COINOVY is a digital finances app')}
                            </p> */}
                            <img className="ourstory-line" src="../../../assets/images/aboutus/lines.svg" alt="" />
                        </div>
                    </div>
                </div>
                {/* our Vision */}
                <div className="our-vision-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="ourvision-image">
                                    <img src="../../../assets/images/aboutus/ourvision-image.svg" width={270} height={306} alt="Coinovy Vision" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="ourvision-text">
                                    <ul className="ourvision">
                                        {/* <li>
                                            <img className="ourvision-line" src="../../../assets/images/Rectangle-64.svg" alt="no-image" />
                                        </li> */}
                                        <li>
                                            <h1 className="ourvision-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">{t('LANDING.Our Vision')}</h1>
                                        </li>
                                    </ul>
                                    <p className="ourvision-subtitle">
                                        {t('LANDING.Coinovy has a vision to simplify')}
                                    </p>
                                    <img src="../../../assets/images/aboutus/lines.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* our mission */}
                <div className="our-mission-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="ourmission-text">
                                    <ul className="ourmission">
                                        <li>
                                            <h1 className="ourmission-title" data-aos="fade-up" data-aos-offset={100} data-aos-delay={50} data-aos-duration={1000} data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
                                            {t('LANDING.our mission')}
                                            </h1>
                                        </li>
                                    </ul>
                                    <p className="ourmission-subtitle">
                                    {t('LANDING.Coinovy’s mission is to offer')}
                                    </p>
                                    <img src="../../../assets/images/aboutus/lines.svg" alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="ourmission-image">
                                    <img src="../../../assets/images/aboutus/ourmission.png" width={400} height={296} alt="Coinovy Mission" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutCoinovy;