import React from "react";

const CryptoExperts = () => {
    return (
        <>
            <div>
  <div className="cryptoexpert-content">
    <img className="expert-img" src="../../../assets/images/blog/expert-bg.png" alt="" />
  </div>
  <div className="cryptoexpert-data">
    <div className="container">
      <h3 className="expert-title">Crypto Experts: The Best Personal Finance Experts You Should Follow</h3>
      <p className="expert-subtitle">Cryptocurrency is a recent trend that has rapidly gained popularity over the past few
        years. Many people have doubled and even tripled their money and investment thanks to this digital currency.
        Consequently, this has encouraged more investors
        and professionals to get into the game as well.</p>
      <p className="expert-subtitle">However, the cryptocurrency market is very volatile and can be very risky when you
        don’t know what it is and how it is involved. Hence, if you want effective and reality-based advice and
        how-to’s then you need to hear from seasoned professionals
        in the field.</p>
      <p className="expert-subtitle"> Unfortunately, this has caused the emergence of bogus companies and professionals
        who claim to be ‘crypto experts’ but hope to steal your money. To avoid falling into this dangerous pitfall,
        below are some of the most reputable personal finance
        experts that you should follow if you’re looking for solid advice on crypto investing.
      </p>
      <div className="row media expert-profiles">
        <img className="col-md-5  profile-image " src="../../../assets/images/blog/expert-img1.png" alt="" />
        <div className="col-md-7 media-body profile-bodys">
          <h3 className="profile-title">Jeremy Schneider of Personal Finance Club</h3>
          <p className="profile-subtitle mt-0">Since Bitcoin, Jeremy Schneider is debatably one of the best people to
            follow if you’re interested in the crypto market. He was one of the lucky investors who have
            experienced the rapid rise of Bitcoin value in just a few months. </p>
          <p className="profile-subtitle mb-0">Schneider advises his followers never to get their feelings ahead of
            them. He emphasizes two simple rules to financial success: Living below your means, and early and
            frequent investing.</p>
        </div>
      </div>
      <div className="row media expert-profiles">
        <div className="col-md-7 media-body profile-bodys">
          <h3 className="profile-title">Changpeng Zhao of Binance</h3>
          <p className="profile-subtitle mt-0">If we talk about cryptocurrency experts, our list would be incomplete
            without one of the biggest names, Changpeng Zhao, who is also known as CZ – CEO of Binance. Being a
            founder of such a large company makes Zhao one of the leading
            experts to follow if you want to level up your crypto investing game.</p>
          <p className="profile-subtitle mb-0"> In terms of trading volume, Binance currently dominates all other
            crypto exchange platforms globally. The platform has managed to stay on the top by continuously
            developing new features, services, and products. Not to mention that
            they provide support for various governments worldwide to have them cater blockchain and
            cryptocurrency. </p>
        </div>
        <img className="col-md-5 profile-image " src="../../../assets/images/blog/binance.png" alt="" />
      </div>
      <div className="row media expert-profiles">
        <img className="col-md-5 align-self-end profile-image" src="../../../assets/images/blog/core.png" alt="" />
        <div className="col-md-7 media-body profile-bodys">
          <h3 className="profile-title">Gavin Andresen of Bitcoin Core</h3>
          <p className="profile-subtitle mt-0">Gavin Andresen developed Bitcoin Core – the original blockchain-based
            software. He is marked on history in terms of cryptocurrency trading via Bitcoin Pro. His popularity
            grew when his name became a controversy in the crypto industry
            when it was believed that he received the code from Satoshi Nakamoto, who is either believed to be a
            highly skilled person or a group of influential people.</p>
          <p className="profile-subtitle mb-0"> While Satoshi remains anonymous, Gavin is believed to be the only one
            who has personally met him. Gavin is also credited for the software’s massive success. </p>
        </div>
      </div>
      <div className="row media expert-profiles">
        <div className="col-md-7 media-body profile-bodys">
          <h3 className="profile-title">Jully-Alma Taveras of Investing Latina</h3>
          <p className="profile-subtitle mt-0">Jully-Alma Taveras strongly believes that the secret to diversifying a
            person’s holdings is by investing in a cryptocurrency asset. In line with this, she recommends
            keeping allocations to at least 1% of your total assets. Taveras
            sticks to the two leading cryptos in the market: Ethereum and Bitcoin.</p>
          <p className="profile-subtitle mb-0"> According to Taveras, one should not compare the crypto market to the
            conventional stock market because the two largely differ from one another. Not to mention, the
            crypto market is a recent and volatile platform that comes with as
            much potential as it does uncertainties. </p>
        </div>
        <img className="col-md-5 align-self-end profile-image " src="../../../assets/images/blog/latina.png" alt="" />
      </div>
      <div className="row media expert-profiles">
        <img className="col-md-5 align-self-end profile-image" src="../../../assets/images/blog/invest-diva.png" alt="" />
        <div className="col-md-7 media-body profile-bodys">
          <h3 className="profile-title">Kiana Danial of Invest Diva</h3>
          <p className="profile-subtitle mt-0">Kiana Danial exposed herself to the cryptocurrency industry in 2016 but
            only started investing two years later. She manages an Instagram account called @InvestDiva where
            she gives relevant and helpful advice to aspiring and new crypto
            investors.
          </p>
          <p className="profile-subtitle mb-0"> She mainly emphasizes putting in great thought before diving into the
            trend – especially our purpose. This is because there is nothing guaranteed on this platform.
            According to her, you might end up disappointed if your goal is fast
            money with little to no effort and planning. </p>
        </div>
      </div>
      <h3 className="expert-title mt-5 ">Conclusion</h3>
      <p className="expert-subtitle">Nothing beats the power of knowledge and experience. For a crypto novice, a good way
        to learn about the cryptocurrency market and become successful is by getting advice from crypto experts. Of
        course, with the hundreds of materials available
        online, you can get bountiful amounts of information about the industry. However, there is only so much that
        you can learn by yourself, and this will barely save you from the threats of fraud.<br /> If you want to
        learn about how to strategize
        and smartly pull the necessary strings, be sure to follow the experts recommended above. A lot of experts
        are willing to share their knowledge, it just takes diligence and patience. By learning from their mistakes,
        you will succeed in
        this market without much difficulty.</p>
    </div>
  </div>
</div>


        </>
    )
}
export default CryptoExperts;